import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { getElementWidth_Mobile, getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import MobileAppBar from "../../../component/1-AppBar/2-MobileAppBar";
import MobileImageCard from "../../../component/3-Products/1-MediaCard_1/2-MobileMediaCard_1";
import MobileMediaCard2 from "../../../component/3-Products/2-MediaCard_2/2-MobileMediaCard_2";
import MobileFooter from "../../../component/2-Footer/2-MobileFooter";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerTelco from "./DrawerTelco";

const MobileTelcoPartnership = ({ descriptions }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState("white");

  const scrollRefs = {
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleItemClick = (id) => {
    if (scrollRefs[id].current) {
      const appBarHeight = getElementWidth_Mobile(160);
      const offset = scrollRefs[id].current.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const menuIconStyle = {
    position: "fixed",
    top: getElementHeight(30) + "px",
    right: getElementWidth(15) + "px",
    zIndex: 9999,
    cursor: "pointer",
    display: openDrawer ? "none" : "block",
    color: menuIconColor,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 100;
      const newMenuIconColor = scrollY > threshold ? "white" : "white";
      setMenuIconColor(newMenuIconColor);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //#region TOP VIDEO CONTENT

  const topData = {
    type: "VIDEO",
    url: "https://cdn.imigames.io/videos/v2/1X1_Nero_mobile.mp4",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <MobileMediaCard2 data={topData} />;

  //#endregion

  //#region DESCRIPTIONS CONTENT
  let section_1 = <MobileImageCard data={descriptions.section_1} />;

  let section_2 = <MobileImageCard data={descriptions.section_2} />;

  let section_3 = <MobileImageCard data={descriptions.section_3} />;

  let section_4 = <MobileImageCard data={descriptions.section_4} />;

  let section_5 = <MobileImageCard data={descriptions.section_5} />;

  const descriptionsContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: getElementWidth_Mobile(20) + "px",
        paddingBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_1"
          ref={scrollRefs["#titleID_1"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What's in the Gaming Industry for Telco?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          The gaming industry is 80% larger than the movie industries around the world. With rapid growth it is estimated to reach USD 270 Billion by 2025.
        </Typography>
      </Box>
      {section_1}
      {section_2}
      <Box
        sx={{
          display: "flex",
          width: getElementWidth_Mobile(600) + "px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_2"
          ref={scrollRefs["#titleID_2"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37", textAlign: "center", justifyContent: "center" }}
        >
          How can a gaming platform help Telco?
        </Typography>
      </Box>
      {section_3}
      {section_4}
      {section_5}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_3"
          ref={scrollRefs["#titleID_3"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What can we do?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          We are a gaming factory. Our assembly line work process enables us to provide custom made games at a fraction of price. Whatever the gaming need may be, from hyper-casual
          gaming portals with an array of games, to multiplayer first person shooters we can provide tailor made gaming solutions with highly secured, real-time leaderboard
          modules.
        </Typography>
      </Box>
    </Box>
  );

  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MobileAppBar onMenuClick={handleDrawerToggle} />
      <MenuIcon style={menuIconStyle} onClick={handleDrawerToggle} />
      <DrawerTelco open={openDrawer} onClose={handleDrawerToggle} onItemClick={handleItemClick} />
      {topContent}
      {descriptionsContent}
      <MobileFooter />
    </Box>
  );
};

export default MobileTelcoPartnership;
