import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { getElementWidth_Mobile, getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import MobileAppBar from "../../../component/1-AppBar/2-MobileAppBar";
import MobileImageCard from "../../../component/3-Products/1-MediaCard_1/2-MobileMediaCard_1";
import MobileMediaCard2 from "../../../component/3-Products/2-MediaCard_2/2-MobileMediaCard_2";
import MobileFooter from "../../../component/2-Footer/2-MobileFooter";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerDigital from "./DrawerDigital";

const MobileDigitalTherapeutics = ({ descriptions }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState("white");

  const scrollRefs = {
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleItemClick = (id) => {
    if (scrollRefs[id].current) {
      const appBarHeight = getElementWidth_Mobile(160);
      const offset = scrollRefs[id].current.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const menuIconStyle = {
    position: "fixed",
    top: getElementHeight(30) + "px",
    right: getElementWidth(15) + "px",
    zIndex: 9999,
    cursor: "pointer",
    display: openDrawer ? "none" : "block",
    color: menuIconColor,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 100;
      const newMenuIconColor = scrollY > threshold ? "white" : "white";
      setMenuIconColor(newMenuIconColor);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //#region TOP VIDEO CONTENT
  const topData = {
    type: "IMAGE",
    url: "assets/images/1-home/1 - products/digitaltherapeutics.gif",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <MobileMediaCard2 data={topData} />;
  //#endregion

  //#region DESCRIPTIONS CONTENT

  let section_1 = <MobileImageCard data={descriptions.section_1} />;

  let section_2 = <MobileImageCard data={descriptions.section_2} />;

  const descriptionsContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: getElementWidth_Mobile(20) + "px",
        paddingBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_1"
          ref={scrollRefs["#titleID_1"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What are Digital Therapeutics?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          Digital Therapeutics combines digital technology and medical science to provide evidence-based therapeutic interventions for patients suffering from various chronic
          conditions including diabetes, cardiovascular diseases, respiratory disorders, mental health disorders, and neurological conditions. These programs are clinically
          validated and can be used as standalone therapies or in conjunction with traditional treatments.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(20) + "px", fontFamily: "Poppins", fontStyle: "italic" }}>
          Evidence: Hollis, C., Falconer, C. J., Martin, J. L., Whittington, C., Stockton, S., Glazebrook, C., ... & Davies, E. B. (2017). Annual research review: Digital health
          interventions for children and young people with mental health problems: a systematic and meta-review. Journal of Child Psychology and Psychiatry, 58(4), 474-503. doi:
          10.1111/jcpp.12663Gustafson, D. H., McTavish, F. M., Chih, M. Y., Atwood, A. K., Johnson, R. A., Boyle, M. G., ... & Shah, D. V. (2014). A smartphone application to
          support recovery from alcoholism: A randomized clinical trial. Journal of Substance Abuse Treatment, 46(1), 54-60. doi: 10.1016/j.jsat.2013.08.003
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_2"
          ref={scrollRefs["#titleID_2"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Use of Digital Games for Cognitive Rehabilitation
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          One of the significant applications of digital therapeutics is cognitive rehabilitation. Cognitive rehabilitation is a therapeutic approach that aims to improve the
          cognitive function of patients suffering from neurological conditions such as traumatic brain injury, stroke, epilepsy or dementia. Digital games have emerged as an
          effective tool for cognitive rehabilitation due to their ability to engage patients in a fun and interactive manner. Digital games for cognitive rehabilitation are
          designed to target specific cognitive domains such as attention, memory, and executive function. These games use evidence-based interventions such as errorless learning,
          spaced retrieval, and cognitive training to improve cognitive function in patients.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(20) + "px", fontFamily: "Poppins", fontStyle: "italic" }}>
          Evidence: A systematic review and meta-analysis published in the Journal of Neuro Engineering and Rehabilitation evaluated the effectiveness of digital games for
          cognitive rehabilitation. The study found that digital games were effective in improving cognitive function in patients with neurological conditions such as stroke and
          traumatic brain injury. Towers, A., Smyth, K., & McNeely, H. (2019). Use of commercial video games for cognitive rehabilitation: a systematic review. Journal of Neuro
          Engineering and Rehabilitation, 16(1), 1-16. doi: 10.1186/s12984-019-0534-1
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: getElementWidth_Mobile(600) + "px",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_3"
          ref={scrollRefs["#titleID_3"]}
          variant="h3"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Our involvement in Digital Therapeutics
        </Typography>
      </Box>
      {section_1}
      {section_2}
    </Box>
  );

  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MobileAppBar onMenuClick={handleDrawerToggle} />
      <MenuIcon style={menuIconStyle} onClick={handleDrawerToggle} />
      <DrawerDigital open={openDrawer} onClose={handleDrawerToggle} onItemClick={handleItemClick} />
      {topContent}
      {descriptionsContent}
      <MobileFooter />
    </Box>
  );
};

export default MobileDigitalTherapeutics;
