import { Box } from "@mui/material";
import React from "react";
import DesktopWellness from "./2-DesktopWellness";
import MobileWellness from "./3-MobileWellness";

const CoporateWellnessPage = () => {
  const descriptions = {
    section_1: {
      title: "Sound public health approach",
      subTitle: "",
      description_1:
        "The impact of a proposed intervention on health of a group depends on the magnitude of the addressed health issues and the efficacy of the solution. ",
      description_2: "",
      image: "assets/images/2-products/4 - corporate wellness/sound.png",
    },

    section_2: {
      title: "Evidence based social psychology at work",
      subTitle: "",
      description_1:
        "The uptake of proposed solutions in a group depends on the social psychology principles underpinning the interventions. Therefore, the out corporate wellness solution has been designed on solution must adhere to best practices in social psychology to ensure optimal uptake. ",
      description_2: "",
      image: "assets/images/2-products/4 - corporate wellness/evidence.png",
    },

    section_3: {
      title: "Leveraging the innate affinity to compete or collaborate",
      subTitle: "",
      description_1:
        "Gamification can be an effective tool for promoting physical activity and other healthy behaviors by making them more engaging, social, and fun. By creating competitions and collaborations, individuals are motivated to adopt healthy habits and are more likely to stick with them over time. ",
      description_2: "Evidence: A systematic review of 19 studies found that gamification interventions significantly increased physical activity levels in participants compared to control groups (Cugelman et al., 2016). A randomized controlled trial of a gamified physical activity program found that participants in the intervention group significantly increased their physical activity levels compared to a control group (Baranowski et al., 2013).",
      image: "assets/images/2-products/4 - corporate wellness/leveraging.png",
    },

    section_4: {
      title: "Social Cognition as a Motivating factor",
      subTitle: "",
      description_1:
        "The institution-wide visibility of health challenge take-up results in resetting normative beliefs to leverage social cognition. This perceived change in social norms is known in social psychology to be one of the most effective motivating factors to promote positive behavioral change.",
      description_2: "Evidence: Bandura, A., Caprara, G. V., & Barbaranelli, C. (2017). A social cognitive theory-based framework for monitoring and supporting changes in diet, physical activity and sedentary behavior. Psychology & Health, 32(3), 357-384. doi: 10.1080/08870446.2016.1277054",
      image: "assets/images/2-products/4 - corporate wellness/social.png",
    },

    section_5: {
      title: "Positive reinforcement of behavioural modifications",
      subTitle: "",
      description_1:
        "Incentivizing a physically active lifestyle through ranking on a leader board. Positive reinforcement of behavior is a well-known factor for behavioral modifications",
      description_2: "Evidence: Tate, D. F., Jackvony, E. H., & Wing, R. R. (2003). Effects of internet behavioral counseling on weight loss in adults at risk for type 2 diabetes: A randomized trial. Journal of the American Medical Association, 289(14), 1833-1836.  O'Connor, P. J., Herring, M. P., & Caravalho, A. (2010). Mental health benefits of strength training in adults. American Journal of Lifestyle Medicine, 4(5), 377-396.",
      image: "assets/images/2-products/4 - corporate wellness/positive.png",
    },

    section_6: {
      title: "Endowment effect/ Foot-in-the- door concept",
      subTitle: "",
      description_1:
        "The institution-wide tournament would have a series of hyper-casual games as an easy entry point given its universal appeal. Since the users who have invested sometime in the game, they would want to make the most of it by taking on a health challenge than someone starting afresh.",
      description_2: "Evidence: Foot-in-the door Technique Freedman, J. L.; Fraser, S. (1966 \"Compliance without pressure: The foot-in-the-door technique\". Journal of Personality and Social Psychology. 4 (2): 195–202. doi:10.1037/h0023552. PMID 5969145. S2CID18761180. Endowment Effect: Beggan, J. (1992). \"On the social nature of nonsocial perception: The mere ownership effect\". Journal of Personality and Social Psychology.",
      image: "assets/images/2-products/4 - corporate wellness/new-endowment.png",
    },

    section_7: {
      subTitle: "Percentage who’d readily pick-up health challenges",
      image: "assets/images/2-products/4 - corporate wellness/bar-chart -1.svg",
    },
    section_8: {
      subTitle: "Percentage who’d readily pick-up a hyper-casual game challenge",
      image: "assets/images/2-products/4 - corporate wellness/bar-chart -2.svg",
    },
    section_9: {
      subTitle: "Percentage who’d readily pick-up a health challenge once committed to the process",
      image: "assets/images/2-products/4 - corporate wellness/bar-chart -3.svg",
    },

    section_10: {
      title: "Individually tailored Health Advices",
      subTitle: "",
      description_1:
        "Information about personal affinities and behavioral risk factors, extracted from mini-quizzes will be used to design and deliver individually tailored health advices.This process of extracting information from an individual to craft person-specific health advices is known as \“Brief Advices\”; ",
      description_2: "An extremely efficient behavioral modification technique based on transtheoretical model in behavioral psychology",
      image: "assets/images/2-products/4 - corporate wellness/health.png",
    },

  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopWellness descriptions={descriptions} />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileWellness descriptions={descriptions} />
      </Box>
    </>
  );
};

export default CoporateWellnessPage;
