import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, Breadcrumbs, Link, Typography } from "@mui/material";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import { PathsUrls } from "../../utils/Data";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";

const DesktopAppBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();
  const appBarHeight = getElementHeight(90);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const breadcrumbItems = [
    { label: "Home", path: PathsUrls.home },
    { label: "Telco Partnership", path: PathsUrls.telcoPartnership },
    { label: "Advergames for Marketing", path: PathsUrls.advergamesForMarketing },
    { label: "Digital Therapeutics", path: PathsUrls.digitalTherapeutics },
    { label: "Corporate Wellness", path: PathsUrls.corporateWellness },
    { label: "Gaming for Websites", path: PathsUrls.gamingForWebsites },
  ];

  const currentBreadcrumbItem = breadcrumbItems.find((item) => item.path === location.pathname);

  const breadcrumbColor = scrollPosition > 0 ? "white" : "white";

  const appBarBackgroundColor = scrollPosition > 0 ? "#CD3C37" : "#CD3C37";
  const appBarBoxShadow = scrollPosition > 0 ? "0px 2px 4px rgba(0, 0, 0, 0.2)" : "none";

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: appBarHeight + "px",
          /* Have to Remove the width */
          width: getElementWidth(1280) + "px",
          backgroundColor: appBarBackgroundColor,
          boxShadow: appBarBoxShadow,
          transition: "background-color 0.5s ease-out, box-shadow 0.5s ease-out",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            gap: "20px",
          }}
        >
          <Box>
            <Link to={PathsUrls.home} component={RouterLink} style={{ textDecoration: "none" }}>
              <img
                height={getElementHeight(80) + "px"}
                width={getElementHeight(58) + "px"}
                src="assets/images/appbar_logo.png"
                alt="IMI Logo"
                style={{ display: "block", margin: "auto 0" }}
              />
            </Link>
          </Box>

          <Box>
            {currentBreadcrumbItem && currentBreadcrumbItem.path !== PathsUrls.home && (
              <Breadcrumbs separator={<NavigateNext fontSize="medium" style={{ color: breadcrumbColor }} />}>
                <Link color="inherit" component={RouterLink} to={PathsUrls.home} style={{ textDecoration: "none", color: breadcrumbColor, fontWeight: "700" }}>
                  Home
                </Link>
                <Typography style={{ color: breadcrumbColor, fontWeight: "700" }}>{currentBreadcrumbItem.label}</Typography>
              </Breadcrumbs>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ height: appBarHeight + "px" }} />
    </Box>
  );
};

export default DesktopAppBar;
