import { Box, Card, CardContent, CardMedia, Grid, Typography, MobileStepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getElementHeight_Mobile, getElementWidth_Mobile } from "../../utils/ScreenManager";

import MobileAppBar from "../../component/1-AppBar/2-MobileAppBar";
import MobileFooter from "../../component/2-Footer/2-MobileFooter";
import { Link } from "react-router-dom";
import { ReactComponent as WaveSvg } from "./wave.svg";

import ClientsWebp from "./clientMobile.webp";

export default function MobileHome({ productsData, productIndex, partnersData }) {
  const steps = productsData.map(({ id, text, subtext, image, link }) => ({
    id,
    label: text,
  }));
  const currentProductLink = productsData[productIndex].link;

  const [activeStep, setActiveStep] = useState(productIndex);

  // Update the active step when productIndex changes
  useEffect(() => {
    setActiveStep(productIndex);
  }, [productIndex]);

  useEffect(() => {
    setActiveStep(0);
  }, [steps.length]);

  const topContent = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          width: getElementWidth_Mobile(720) + "px",
        }}
      >
        <Link to={currentProductLink} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: getElementWidth_Mobile(720) + "px",
            }}
          >
            <img width={getElementWidth_Mobile(600) + "px"} height={getElementWidth_Mobile(600) + "px"} alt="IMG" src={productsData[productIndex].image} />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: getElementHeight_Mobile(400) + "px",
              width: getElementWidth_Mobile(720) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  fontSize: getElementHeight_Mobile(70) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                }}
              >
                {productsData[productIndex].text}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementHeight_Mobile(45) + "px",
                  fontFamily: "Poppins",
                  color: "black",
                }}
              >
                {productsData[productIndex].subtext}
              </Typography>
            </Box>
          </Box>
        </Link>

        {/* Little buttons -MobileStepper*/}
        <div>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "transparent",
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: "#CD3C37", // Changed the active dot color to #CD3C37
              },
            }}
          />
        </div>
      </Box>
      <WaveSvg style={{ width: "100%", height: "auto" }} alt="wave-splatter" />
    </>
  );

  //#endregion

  //#region PARTNERS CONTENT

  const partnersContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: getElementWidth_Mobile(720) + "px",
        paddingTop: getElementWidth_Mobile(30) + "px",
        paddingBottom: getElementWidth_Mobile(30) + "px",
      }}
    >
      <Box
        sx={{
          width: getElementWidth_Mobile(720) + "px",
          paddingBottom: getElementWidth_Mobile(30) + "px",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center", fontSize: getElementWidth_Mobile(50) + "px" }}>
          Partners
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center", fontSize: getElementWidth_Mobile(30) + "px" }}>
          A few of many who has trusted us.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: getElementWidth_Mobile(720) + "px",
        }}
      >
        <img src={ClientsWebp} alt="clients" width="90%" />
      </Box>
    </Box>
  );
  //#endregion

  //#region PRODUCTS
  const productsContent = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: getElementWidth_Mobile(720) + "px",
          paddingTop: getElementWidth_Mobile(30) + "px",
          paddingBottom: getElementWidth_Mobile(30) + "px",
        }}
      >
        <Box
          sx={{
            width: getElementWidth_Mobile(720) + "px",
            paddingBottom: getElementWidth_Mobile(30) + "px",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center", fontSize: getElementWidth_Mobile(50) + "px" }}>
            Products
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center", fontSize: getElementWidth_Mobile(30) + "px" }}>
            We have specialized a few products for you
          </Typography>
        </Box>

        <Box
          sx={{
            width: getElementWidth_Mobile(720) + "px",
            paddingTop: getElementWidth_Mobile(50) + "px",
            paddingBottom: getElementWidth_Mobile(50) + "px",
          }}
        >
          <Grid container justifyContent="center" spacing={getElementWidth_Mobile(40) + "px"}>
            {productsData.map(({ id, text, subtext, image, link }) => {
              return (
                <Grid item sx={{ textAlign: "center" }} key={id}>
                  <Link to={link} style={{ textDecoration: "none" }}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        height: "400px",
                        width: "260px",
                        borderRadius: "4px",
                        boxShadow: " rgb(0 0 0 / 16%) 0px 0px 9px -1px",
                      }}
                    >
                      <CardMedia sx={{ width: "250px", height: "250px" }} image={image} title="Telco Partnerships" />
                      <CardContent>
                        <Typography sx={{ fontWeight: "700", textAlign: "center", fontSize: "20px" }}>{text}</Typography>
                        <Typography sx={{ fontWeight: "400", textAlign: "center", fontSize: "12px" }}>{subtext}</Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MobileAppBar />
      {topContent}
      {partnersContent}
      {productsContent}
      <MobileFooter />
    </Box>
  );
}
