import { Box, Typography, Skeleton } from "@mui/material";
import React, { useState,useEffect } from "react";
import { getElementWidth_Mobile } from "../../../utils/ScreenManager";

const MobileImageCard = ({ data: { title, subTitle, description_1, description_2, image } }) => {
  const [loading, setLoading] = useState(true);

  const onImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const imgSrc = image;
    if (imgSrc) {
      let img = new window.Image();
      img.src = imgSrc;
      img.onload = onImageLoad;
    }
  }, [image]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: getElementWidth_Mobile(20) + "px",
        marginBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
       {loading ? (
          <Skeleton
            variant="rectangular"
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
          />
        ) : (
          <img
            style={{ display: loading ? "none" : "block" }}
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
            alt="IMG"
            src={image}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(35) + "px", fontFamily: "Poppins" }}>
          {title}
        </Typography>
        <Box
          sx={{
            height: getElementWidth_Mobile(20) + "px",
          }}
        ></Box>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(30) + "px", fontFamily: "Poppins" }}>
          {subTitle}
        </Typography>
        <Box
          sx={{
            height: getElementWidth_Mobile(20) + "px",
          }}
        ></Box>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          {description_1}
        </Typography>
        <Box
          sx={{
            height: getElementWidth_Mobile(20) + "px",
          }}
        ></Box>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(20) + "px", fontFamily: "Poppins", fontStyle: "italic" }}>
          {description_2}
        </Typography>
      </Box>
    </Box>
  );
};

export default MobileImageCard;
