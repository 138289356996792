import React, { useRef } from "react";
import DesktopFooter from "../../../component/2-Footer/1-DesktopFooter";
import DesktopMediaCard2 from "../../../component/3-Products/2-MediaCard_2/1-DesktopMediaCard_2";
import DesktopMediaCard1 from "../../../component/3-Products/1-MediaCard_1/1-DesktopMediaCard_1";
import DesktopAppBar from "../../../component/1-AppBar/1-DesktopAppBar";
import { Drawer, Box, List, ListItem, ListItemText, Collapse, Typography } from "@mui/material";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ClippedDrawer({ descriptions }) {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const scrollRefs = {
    // Define scroll references for each section
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
    "#titleID_4": useRef(null),
  };
  const handleClick1 = () => {
    setOpenMenu1(!openMenu1);
    setSelectedItem("#titleID_1");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_1"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
    setSelectedItem("#titleID_2");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_2"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
    setSelectedItem("#titleID_3");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_3"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  const handleClick4 = () => {
    setOpenMenu4(!openMenu4);
    setSelectedItem("#titleID_4");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_4"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  //#region TOP VIDEO CONTENT
  const topData = {
    type: "VIDEO",
    url: "https://s3.ap-southeast-1.amazonaws.com/cdn.imigames.io/videos/Wellness_End_video.mp4",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <DesktopMediaCard2 data={topData} />;
  //#endregion

  //#region DESCRIPTIONS CONTENT

  let section_1 = <DesktopMediaCard1 data={descriptions.section_1} />;

  let section_2 = <DesktopMediaCard1 data={descriptions.section_2} />;

  let section_3 = <DesktopMediaCard1 data={descriptions.section_3} />;

  let section_4 = <DesktopMediaCard1 data={descriptions.section_4} />;

  let section_5 = <DesktopMediaCard1 data={descriptions.section_5} />;

  let section_6 = <DesktopMediaCard1 data={descriptions.section_6} />;

  let section_7 = <DesktopMediaCard1 data={descriptions.section_7} />;

  let section_8 = <DesktopMediaCard1 data={descriptions.section_8} />;

  let section_9 = <DesktopMediaCard1 data={descriptions.section_9} />;

  let section_10 = <DesktopMediaCard1 data={descriptions.section_10} />;

  const drawerWidth = 200;

  return (
    <Box>
      <DesktopAppBar />
      <Box sx={{ display: "flex", p: 0 }}>
        {/*         <CssBaseline />
         */}

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ height: getElementHeight(90) + "px" }}></Box>

          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem
                button
                onClick={handleClick1}
                style={{
                  backgroundColor: selectedItem === "#titleID_1" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText
                  primary="What do we address area?
"
                />
                {openMenu1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={openMenu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Direct costs" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Indirect cost due to worker attrition and replacement" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick2}
                style={{
                  backgroundColor: selectedItem === "#titleID_2" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText
                  primary="What do we propse?
"
                />
                {openMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="What does this include?" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="The health challenges include" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick3}
                style={{
                  backgroundColor: selectedItem === "#titleID_3" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="What’s unique about our wellness solution?" />
                {openMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Sound public health approach"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Evidence based social psychology at work"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Leveraging the innate affinity to compete or collaborate"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Social Cognition as a Motivating factor"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Positive reinforcement of behavioural modifications"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Endowment effect/ Foot-in-the- door concept"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Individually tailored Health Advices"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="
Recommending tailor-made screening packages"
                    />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick4}
                style={{
                  backgroundColor: selectedItem === "#titleID_4" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="In a nutshell, we aim to…." />
                {openMenu4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="Address worker burnout
..."
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Box>
        </Drawer>

        <Box>
          {topContent}

          {/* What do we address area?-AREA-Open */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_1"
                ref={scrollRefs["#titleID_1"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What do we address area?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                We address two burning issues in the workforce
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="assets/images/2-products/4 - corporate wellness/illness.png" alt="image 1" width="300" height="auto" />
                <img src="assets/images/2-products/4 - corporate wellness/burn-out.png" alt="image 2" width="300" height="auto" />
              </div>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                Both Illness and burnout incur heavy costs to the industry
              </Typography>
            </Box>
          </Box>

          {/* What do we address?-AREA-Close */}

          {/*Direct Cost area and Indirect Cost-AREA-Open*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",

                alignItems: "center",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
                width: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  paddingTop: getElementWidth(20) + "px",
                  paddingBottom: getElementWidth(20) + "px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img width={getElementWidth(350) + "px"} height={getElementWidth(350) + "px"} alt="IMG" src="assets/images/2-products/4 - corporate wellness/directCost.png" />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: getElementWidth(580) + "px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: getElementWidth(30) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Direct costs
                    </Typography>
                    <Box
                      sx={{
                        height: getElementWidth(20) + "px",
                      }}
                    ></Box>

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: getElementWidth(20) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Direct costs are due to
                      <ul style={{ listStyle: "disc" }}>
                        <li>Wages paid to absent employees.</li>
                        <li>High-cost replacement workers</li>
                        <li>
                          Poor quality of goods/services resulting from
                          <ul style={{ listStyle: "disc" }}>
                            <li>Overtime </li>
                            <li>Fatigue </li>
                            <li>Understaffing </li>
                          </ul>
                        </li>
                        <li>Reduced productivity</li>
                        <li>
                          Safety issues
                          <ul style={{ listStyle: "disc" }}>
                            <li>inadequately trained employees filling in </li>
                            <li>rushing to catch up </li>
                          </ul>
                        </li>
                      </ul>
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: getElementWidth(20) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      These add-up to nearly <b>$ 1685 per worker</b> in a year.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/*Direct Cost-AREA-Close*/}

          {/*Indirect Cost area and Indirect Cost-AREA-Open*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
                width: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: getElementWidth(20) + "px",
                  paddingBottom: getElementWidth(20) + "px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img width={getElementWidth(350) + "px"} height={getElementWidth(350) + "px"} alt="IMG" src="assets/images/2-products/4 - corporate wellness/indirectCost.png" />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: getElementWidth(580) + "px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: getElementWidth(30) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Indirect cost due to worker attrition and replacement
                    </Typography>
                    <Box
                      sx={{
                        height: getElementWidth(20) + "px",
                      }}
                    ></Box>

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: getElementWidth(20) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Such costs could be attributed to
                      <ul>
                        <li>
                          Recruitment
                          <ul style={{ listStyle: "disc" }}>
                            <li>Advertising </li>
                            <li>Interviewing </li>
                            <li>Screening </li>
                            <li>Hiring </li>
                          </ul>
                        </li>
                        <li>
                          Onboarding
                          <ul style={{ listStyle: "disc" }}>
                            <li>Training </li>
                            <li>Management </li>
                          </ul>
                        </li>
                        <li>Lower level of productivity of new workers </li>
                      </ul>
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: getElementWidth(20) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Every time a business replaces a salaried employee it costs nearly <b>6 months’ salary</b> of the recruited worker{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/*Indirect Cost-AREA-Close*/}

          {/*What do we propose?-AREA-Open*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_2"
                ref={scrollRefs["#titleID_2"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What do we propse?{" "}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                <p>
                  {" "}
                  We propose gamifying healthy behaviour through an institution-wide tournament revolving around a <b>"wellness factor"</b> feeding directly into the scores of a
                  leader board.{" "}
                </p>
                <p>The wellness factor depends on the performance on various health challenges an individual takes-up </p>
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(30) + "px",
                  fontFamily: "Poppins",
                }}
              >
                What does this include?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                <p>
                  {" "}
                  We propose gamifying healthy behaviour through an institution-wide tournament revolving around a "wellness factor" feeding directly into the scores of a leader
                  board.
                </p>
                <p>The wellness factor depends on the performance on various health challenges an individual takes-up </p>
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(30) + "px",
                  fontFamily: "Poppins",
                }}
              >
                The health challenges include
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                A gamified step-counter. An Augmented Reality based Exergame. A Dietary Journal. Digitized Anxiolytic Mental Exercises. Information about individual risk factors,
                personal affinities and concerns are learnt through questions interspersed inbetween different game segments of the tournamemt.{" "}
                <ol style={{ justifyContent: "center", textAlign: "start" }}>
                  Information thus gathered will be used to
                  <li>Recommend a personalized battery of screening tests, ensuring that no money or time is wasted on unnecessary investigations and the salient ones are done</li>
                  <li>To individually tailor brief advices to align with personal affinities and concerns to maximise compliance </li>
                  <li>To generate a company-level risk factor profile </li>
                </ol>
              </Typography>
            </Box>
          </Box>
          {/*What do we propose?-AREA-Close*/}

          {/* What’s unique about our wellness solution?-AREA-Open */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "50%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_3"
                ref={scrollRefs["#titleID_3"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What’s unique about our wellness solution?{" "}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                Our product represents the perfect marriage between evidence based public health practices and social psychology to ensure high levels of engagement and maximum
                effectiveness.{" "}
              </Typography>
            </Box>
          </Box>
          {/* What’s unique about our wellness solution?
-AREA-Close */}

          {section_1}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "70%",
                textAlign: "center",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <img src="assets/images/2-products/4 - corporate wellness/draft_1.png" alt="draft_1" width="auto" height="auto" />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                <p>
                  Each disease to be prioritized, and each behavioral or environmental risk factor to be addressed through the intervention will be decided according to the country
                  specific risk profile of the given to the age group and gender.
                </p>
                <p>This will ensure that the most prevalent and salient risk factors are addressed while keeping solely opinion-based decisions off the table.</p>
              </Typography>
              <img src="assets/images/2-products/4 - corporate wellness/draft_2.png" alt="draft_2" width="auto" height="auto" />
            </Box>
          </Box>
          {section_2}
          {section_3}
          {section_4}
          {section_5}
          {section_6}
          {section_7}
          {section_8}
          {section_9}

          {/* Individualized Approach Area-Open */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(35) + "px",
                  fontFamily: "Poppins",
                  color: "blue",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Individualized Approach
              </Typography>
            </Box>
          </Box>

          {section_10}
          {/* Individualized Approach Area-Close */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",

                alignItems: "center",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
                width: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  paddingTop: getElementWidth(20) + "px",
                  paddingBottom: getElementWidth(20) + "px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img width={getElementWidth(350) + "px"} height={getElementWidth(350) + "px"} alt="IMG" src="assets/images/2-products/4 - corporate wellness/recommending.png" />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: getElementWidth(580) + "px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: getElementWidth(30) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Recommending tailor-made screening packages
                    </Typography>
                    <Box
                      sx={{
                        height: getElementWidth(20) + "px",
                      }}
                    ></Box>

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: getElementWidth(20) + "px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Answers in mini-quizzes will be analyzed to identify the user’s specific health risk profile. An individualized screening battery would be suggested when it
                      is deemed useful, based on this user specific risk profile. This will be superior to traditional one-size-fits-all biochemical screening test batteries as it
                      <ul>
                        <li>Prevents the waste of money in doing investigations with a very low yield </li>
                        <li>Detects simple correctible conditions leading to low productivity/ attendance/ morale </li>
                      </ul>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/*In a Nutshell Area-Open*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_4"
                ref={scrollRefs["#titleID_4"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                }}
              >
                In a nutshell, we aim to….
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                <ul style={{ textAlign: "start" }}>
                  <li>
                    Address worker burnout
                    <ul style={{ listStyle: "disc" }}>
                      <li>By improving workplace social dynamics through an e-sports tournament within the institution. </li>
                    </ul>
                  </li>
                  <li>
                    Improve worker health by
                    <ul style={{ listStyle: "disc" }}>
                      <li>
                        Introducing a set of <b>health challenges</b> to create an inclusive culture to promote positive behavioral changes at workplace, leveraging social
                        cognition{" "}
                      </li>
                      <li>
                        Suggesting <b>screening tests</b> (where necessary) based on user specific risk profile
                      </li>
                      <li>
                        Designing and delivering <b>“Brief Advices”</b> according to the transtheoretical theory in behavioral psychology to elicit a positive behavioral change
                      </li>
                      <li>
                        Assessing the <b>institutional level risk profile</b> and suggesting possible institutional level interventions*
                      </li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </Box>
          </Box>
          {/*In a Nutshell Area-Close*/}

          <DesktopFooter />
        </Box>
      </Box>
    </Box>
  );
}
