import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { getElementWidth_Mobile, getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import MobileAppBar from "../../../component/1-AppBar/2-MobileAppBar";
import MobileImageCard from "../../../component/3-Products/1-MediaCard_1/2-MobileMediaCard_1";
import MobileMediaCard2 from "../../../component/3-Products/2-MediaCard_2/2-MobileMediaCard_2";
import MobileFooter from "../../../component/2-Footer/2-MobileFooter";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerGaming from "./DrawerGaming";

const MobileGamingForWebsites = ({ descriptions }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState("white");

  const scrollRefs = {
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
    "#titleID_4": useRef(null),
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleItemClick = (id) => {
    if (scrollRefs[id].current) {
      const appBarHeight = getElementWidth_Mobile(160);
      const offset = scrollRefs[id].current.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const menuIconStyle = {
    position: "fixed",
    top: getElementHeight(30) + "px",
    right: getElementWidth(15) + "px",
    zIndex: 9999,
    cursor: "pointer",
    display: openDrawer ? "none" : "block",
    color: menuIconColor,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 100;
      const newMenuIconColor = scrollY > threshold ? "white" : "white";
      setMenuIconColor(newMenuIconColor);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //#region TOP VIDEO CONTENT
  const topData = {
    type: "VIDEO",
    url: "https://cdn.imigames.io/videos/v2/1X1_imi_intro.mp4",
    title: " ",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <MobileMediaCard2 data={topData} />;
  //#endregion

  //#region DESCRIPTIONS CONTENT
  let section_1 = <MobileImageCard data={descriptions.section_1} />;

  let section_2 = <MobileImageCard data={descriptions.section_2} />;

  let section_3 = <MobileImageCard data={descriptions.section_3} />;

  let section_4 = <MobileImageCard data={descriptions.section_4} />;

  const descriptionsContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: getElementWidth_Mobile(20) + "px",
        paddingBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_1"
          ref={scrollRefs["#titleID_1"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Websites
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(30) + "px", fontFamily: "Poppins" }}>
          What are Hyper-casual games?
        </Typography>
        <Box
          sx={{
            height: getElementWidth_Mobile(20) + "px",
          }}
        ></Box>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          Hyper-casual games are a genre of mobile games that are characterized by their simple mechanics, short play sessions, and high replayability. They are designed to be easy
          to pick up and play, with minimal instructions or tutorial required.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_2"
          ref={scrollRefs["#titleID_2"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Why have them on your Web?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}></Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(20) + "px", fontFamily: "Poppins", fontStyle: "italic" }}></Typography>
      </Box>
      {section_1}
      {section_2}
      {section_3}
      {section_4}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_3"
          ref={scrollRefs["#titleID_3"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Why us?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          IMI specializes in the production of hyper casual games for niche markets. The 40 strong teams of Developers, Marketers, Designers and Psychologists will address concerns
          and objectives of each client on a case-by-case basis. Production of branded gaming content and customization for client requirements come easy to us. The almost assembly
          line- like production process of our gaming factory ensures that you get a quality guaranteed product at a fraction of a price!
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(20) + "px", fontFamily: "Poppins", fontStyle: "italic" }}></Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_4"
          ref={scrollRefs["#titleID_4"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Interested in getting to know more on what we have on offer?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          Contact us.
        </Typography>
      </Box>
      <Box
        sx={{
          height: getElementWidth_Mobile(30) + "px",
        }}
      ></Box>
    </Box>
  );

  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MobileAppBar onMenuClick={handleDrawerToggle} />
      <MenuIcon style={menuIconStyle} onClick={handleDrawerToggle} />
      <DrawerGaming open={openDrawer} onClose={handleDrawerToggle} onItemClick={handleItemClick} />
      {topContent}
      {descriptionsContent}
      <MobileFooter />
    </Box>
  );
};

export default MobileGamingForWebsites;
