import React, { useRef } from "react";
import DesktopFooter from "../../../component/2-Footer/1-DesktopFooter";
import DesktopMediaCard2 from "../../../component/3-Products/2-MediaCard_2/1-DesktopMediaCard_2";
import DesktopMediaCard1 from "../../../component/3-Products/1-MediaCard_1/1-DesktopMediaCard_1";
import DesktopAppBar from "../../../component/1-AppBar/1-DesktopAppBar";
import { Drawer, Box, List, ListItem, ListItemText, Collapse, Typography } from "@mui/material";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ClippedDrawer({ descriptions }) {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const scrollRefs = {
    // Define scroll references for each section
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
    "#titleID_4": useRef(null),
  };
  const handleClick1 = () => {
    setOpenMenu1(!openMenu1);
    setSelectedItem("#titleID_1");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_1"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
    setSelectedItem("#titleID_2");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_2"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
    setSelectedItem("#titleID_3");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_3"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  const handleClick4 = () => {
    setOpenMenu4(!openMenu4);
    setSelectedItem("#titleID_4");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_4"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  //#region TOP VIDEO CONTENT
  const topData = {
    type: "VIDEO",
    url: "https://s3.ap-southeast-1.amazonaws.com/cdn.imigames.io/videos/Advergames_video.mp4",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <DesktopMediaCard2 data={topData} />;
  //#endregion

  //#region DESCRIPTIONS CONTENT

  let section_1 = <DesktopMediaCard1 data={descriptions.section_1} />;

  let section_2 = <DesktopMediaCard1 data={descriptions.section_2} />;

  let section_3 = <DesktopMediaCard1 data={descriptions.section_3} />;

  let section_4 = <DesktopMediaCard1 data={descriptions.section_4} />;

  let section_5 = <DesktopMediaCard1 data={descriptions.section_5} />;

  let section_6 = <DesktopMediaCard1 data={descriptions.section_6} />;

  let section_7 = <DesktopMediaCard1 data={descriptions.section_7} />;

  let section_8 = <DesktopMediaCard1 data={descriptions.section_8} />;

  const drawerWidth = 200;

  return (
    <Box>
      <DesktopAppBar />
      <Box sx={{ display: "flex", p: 0 }}>
        {/*         <CssBaseline />
         */}

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ height: getElementHeight(90) + "px" }}></Box>

          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem
                button
                onClick={handleClick1}
                style={{
                  backgroundColor: selectedItem === "#titleID_1" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="What is an Advergame?" />
                {openMenu1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={openMenu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="An advergame is a type of video game..." />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick2}
                style={{
                  backgroundColor: selectedItem === "#titleID_2" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="Why go for an advergame?" />
                {openMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Increased Brand Awareness" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Increased Customer Engagement" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Enhanced Customer Loyalty" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Increased Sales" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Improved Visibility on Social Media" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="No Wear-Out Effect!" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick3}
                style={{
                  backgroundColor: selectedItem === "#titleID_3" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="How can this be put to use?" />
                {openMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="An advergame linked to a QR code on your pack?" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="A Social Media campaign on roids!" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick4}
                style={{
                  backgroundColor: selectedItem === "#titleID_4" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="What's holding you back?" />
                {openMenu4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Please let us know, for we really can't think of any? :-D" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Box>
        </Drawer>

        <Box>
          {topContent}

          {/* What is an Advergame?-AREA-Open */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_1"
                ref={scrollRefs["#titleID_1"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What is an Advergame?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                An advergame is a type of video game that is used to advertise a product, service, or brand. Advergames are typically designed to be entertaining and engaging,
                while also providing an opportunity for the player to learn more about the product or brand. Everything in an advergame, from the storyline, to characters and even
                the color scheme of the gaming environment are designed to increase brand awareness and purchase intention.
              </Typography>
            </Box>
          </Box>
          {/* What is an Advergame?-AREA-Close */}

          {/*Why go for an advergame?-AREA-Open*/}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
              width: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
                width: "80%",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_2"
                ref={scrollRefs["#titleID_2"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Why go for an Advergame?
              </Typography>
            </Box>
          </Box>
          {/*Why go for an advergame?-AREA-Close*/}
          {section_1}
          {section_2}
          {section_3}
          {section_4}
          {section_5}
          {section_6}

          {/* How can this be put to use?-AREA-Open */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_3"
                ref={scrollRefs["#titleID_3"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                How can this be put to use?
              </Typography>
            </Box>
          </Box>
          {/* How can this be put to use?-AREA-Close */}
          {section_7}
          {section_8}

          {/* What's holding you back?-AREA-Open*/}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_4"
                ref={scrollRefs["#titleID_4"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What's holding you back?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Please let us know, for we really can't think of any? :-D
              </Typography>
            </Box>
          </Box>
          {/* What's holding you back?-AREA-Close*/}

          <DesktopFooter />
        </Box>
      </Box>
    </Box>
  );
}
