import { Box, Skeleton, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import { getElementWidth_Mobile } from "../../../utils/ScreenManager";

const MobileMediaCard2 = ({ data: { type, url, title, subTitle, description_1, description_2 } }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    if (type === "VIDEO") {
      const videoElement = document.createElement("video");
      videoElement.src = url;
      videoElement.onloadeddata = handleVideoLoaded;
    } else if (type === "IMAGE") {
      const imageElement = document.createElement("img");
      imageElement.src = url;
      imageElement.onload = handleVideoLoaded;
    }
  }, [type, url]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: getElementWidth_Mobile(720) + "px",
        marginTop: getElementWidth_Mobile(20) + "px",
        marginBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      <Box
        sx={{
          height: getElementWidth_Mobile(30) + "px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {type === "VIDEO" && (
          <>
            {!videoLoaded && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            )}
            <video
              style={{
                display: videoLoaded ? "block" : "none",
                objectFit: "fill",
                overflow: "hidden",
              }}
              height={getElementWidth_Mobile(600) + "px"}
              autoPlay
              loop
              muted
              onLoadedData={handleVideoLoaded}
            >
              <source src={url} type="video/mp4" />
            </video>
          </>
        )}
        {type == "IMAGE" && (
          <img
            width={getElementWidth_Mobile(600) + "px"}
            height={getElementWidth_Mobile(600) + "px"}
            alt="IMG"
            src={url}
          />
        )}
      </Box>
      <Box
        sx={{
          height: getElementWidth_Mobile(30) + "px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins",color:"#CD3C37" }}>
          {title}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(30) + "px", fontFamily: "Poppins" }}>
          {subTitle}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          {description_1}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(20) + "px", fontFamily: "Poppins", fontStyle: "italic" }}>
          {description_2}
        </Typography>
      </Box>
    </Box>
  );
};

export default MobileMediaCard2;
