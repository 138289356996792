import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { getElementWidth } from "../../../utils/ScreenManager";

const DesktopMediaCard1 = ({ data: { title, subTitle, description_1, description_2, image }, drawerWidth }) => {
  const [loading, setLoading] = useState(true);

  const onImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const imgSrc = image;
    if (imgSrc) {
      let img = new window.Image();
      img.src = imgSrc;
      img.onload = onImageLoad;
    }
  }, [image]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: getElementWidth(20) + "px",
        paddingBottom: getElementWidth(20) + "px",
        width: "100%",
      }}
    >
      <Box sx={{ width: getElementWidth(150) + "px" }}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width={getElementWidth(350) + "px"} height={getElementWidth(350) + "px"} />
        ) : (
          <img style={{ display: loading ? "none" : "block" }} width={getElementWidth(350) + "px"} height={getElementWidth(350) + "px"} alt="IMG" src={image} />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "700",
            fontSize: getElementWidth(30) + "px",
            fontFamily: "Poppins",
          }}
        >
          {title}
        </Typography>
        <Box sx={{ height: getElementWidth(20) + "px" }}></Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            fontSize: getElementWidth(25) + "px",
            fontFamily: "Poppins",
          }}
        >
          {subTitle}
        </Typography>
        <Box sx={{ height: getElementWidth(20) + "px" }}></Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            fontSize: getElementWidth(20) + "px",
            fontFamily: "Poppins",
          }}
        >
          {description_1}
        </Typography>
        <Box sx={{ height: getElementWidth(20) + "px" }}></Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            fontSize: getElementWidth(15) + "px",
            fontFamily: "Poppins",
            fontStyle: "italic",
          }}
        >
          {description_2}
        </Typography>
      </Box>
      <Box sx={{ width: getElementWidth(150) + "px" }}></Box>
    </Box>
  );
};

export default DesktopMediaCard1;
