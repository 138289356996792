import { Box, Typography, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";

const DesktopMediaCard2 = ({ data: { type, url, title, subTitle, description_1, description_2 }, drawerWidth }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    if (type === "VIDEO") {
      const videoElement = document.createElement("video");
      videoElement.src = url;
      videoElement.onloadeddata = handleVideoLoaded;
    } else if (type === "IMAGE") {
      const imageElement = document.createElement("img");
      imageElement.src = url;
      imageElement.onload = handleVideoLoaded;
    }
  }, [type, url]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: getElementHeight(30) + "px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: getElementHeight(600) + "px",
          width: "100%",
          position: "relative",
        }}
      >
        {type === "VIDEO" && (
          <>
            {videoLoaded ? (
              <video
                style={{
                  display: videoLoaded ? "block" : "none",
                  objectFit: "fill",
                  overflow: "hidden",
                }}
                height={getElementHeight(600) + "px"}
                autoPlay
                loop
                muted
                onLoadedData={handleVideoLoaded}
              >
                <source src={url} type="video/mp4" />
              </video>
            ) : (
              <Skeleton variant="rectangular" width="80%" height={getElementHeight(600) + "px"} />
            )}
          </>
        )}
        {type === "IMAGE" && <img width={getElementHeight(600) + "px"} height={getElementHeight(600) + "px"} alt="IMG" src={url} />}
      </Box>
      <Box
        sx={{
          height: getElementHeight(30) + "px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth(580) + "px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "700",
            fontSize: getElementWidth(40) + "px",
            fontFamily: "Poppins",
            color: "#CD3C37",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            fontSize: getElementWidth(25) + "px",
            fontFamily: "Poppins",
          }}
        >
          {subTitle}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            fontSize: getElementWidth(20) + "px",
            fontFamily: "Poppins",
          }}
        >
          {description_1}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            fontSize: getElementWidth(15) + "px",
            fontFamily: "Poppins",
            fontStyle: "italic",
          }}
        >
          {description_2}
        </Typography>
      </Box>
    </Box>
  );
};

export default DesktopMediaCard2;
