import { Box } from "@mui/material";
import React from "react";
import DesktopAdvergamesForMarketing from "./2-DesktopAdvergamesForMarketing";
import MobileAdvergamesForMarketing from "./3-MobileAdvergamesForMarketing";

const AdvergamesForMarketingPage = () => {
  const descriptions = {
    section_1: {
      title: "Increased Brand Awareness",
      subTitle: "",
      description_1:
        "When customers engage with an advergame, they are more likely to remember the company's brand.The more enjoyable the advergame, the more likely customers are to remember and engage with the brand",
      description_2:
        "Evidence: A study conducted by researchers at the University of Missouri. found that the advergame helped to create a positive association with the brand and increased brand recall. The study was published in the Journal of Advertising Research in 2011.",
      image: "assets/images/2-products/2 - advergames for marketing/1.png",
    },

    section_2: {
      title: "Increased Customer Engagement",
      subTitle: "",
      description_1:
        "When customers engage with an advergame, they are more likely to interact with the product. The more enjoyable the advergame, the more likely customers are to engage with the product and purchase it.",
      description_2:
        "Evidence: A study at the University of California, Los Angeles and the University of San Francisco, published in the Journal of Advertising in 2013, found that customers who engage with an advergame are more likely to interact with the associated product. The study, suggested that participants who played the advergame were more likely to seek out additional information about the product, try the product, and make a purchase compared to those who did not play the game.",
      image: "assets/images/2-products/2 - advergames for marketing/2.png",
    },

    section_3: {
      title: "Enhanced Customer Loyalty",
      subTitle: "",
      description_1: "Research has proven that customers who engage with advergames are more likely to become loyal customers.",
      description_2:
        'Evidence: A research from "Seoul National University" in their paper "The effects of brand loyalty and brand trust in online game advertising" found that Advergames increase brand loyalty by creating a positive emotional experience, building brand trust and fostering brand community among players. This research was published in the Journal of Advertising Research in 2010.',
      image: "assets/images/2-products/2 - advergames for marketing/3.png",
    },

    section_4: {
      title: "Increased Sales",
      subTitle: "",
      description_1:
        "More enjoyable the advergame, the more likely customers are to purchase the product. This is due to a phenomenon called transference theory, where the pleasurable experience elicited by the gameplay is transferred to the brand",
      description_2:
        "Evidence: A study by researchers at the University of Wisconsin-Madison found that advergames can increase purchase intention. The study, published in the Journal of Interactive Advertising in 2009, surveyed participants after they played an advergame featuring a product and found that they were more likely to express a desire to purchase than those who were exposed to a traditional advertisement.",
      image: "assets/images/2-products/2 - advergames for marketing/4.png",
    },

    section_5: {
      title: "Improved Visibility on Social Media",
      subTitle: "",
      description_1:
        "People love to share their gaming accomplishments with their peers on Social Media. This can be used to create visibility on social media platforms and increase brand. Since the social circuits on social media is mainly made-up of like-minded individuals through selection, the targeting is extremely precise as well",
      description_2: 'Evidence: The Impact of Advergames on Consumer Attitudes and Brand Equity" by Hiram Ting, Nor Azila Noh, and Mohd Suki Bin Othman.',
      image: "assets/images/2-products/2 - advergames for marketing/5.png",
    },
    section_6: {
      title: "No Wear-Out Effect!",
      subTitle: "",
      description_1:
        "Unlike TV and other traditional channels of advertisements, advergames have no wear-out effect, as the decision to play the game remains with the user. This means that the effect of the advergame can keep growing with every repeated exposure and lasts longer.",
      description_2:
        "Evidence: A study at the University of California, published in the Journal of Advertising in 2013, found that advergames have a longer lasting impact on consumer attitudes and behavior compared to traditional advertisements.",
      image: "assets/images/2-products/2 - advergames for marketing/6.png",
    },
    section_7: {
      title: "An advergame linked to a QR code on your pack?",
      subTitle: "",
      description_1:
        'Yes! The most immersive form of advertising your brand can have, with immensely repetitive exposure, no wear-out effect, unparalleled purchase intention formation, and exponential reach in social media circuitsCan fit inside a 1"x1" square on the pack of a product!',
      description_2: "",
      image: "assets/images/2-products/2 - advergames for marketing/7.png",
    },
    section_8: {
      title: "A Social Media campaign on roids!",
      subTitle: "",
      description_1:
        "Yes! Your brilliant marketing message may need not be just a blur in a scrolling frenzy on social media. It needs not be buried under a mass of personally related content seen by your target audience. You can have your target audience live, breath and experience your marketing message in an enthralling advergame and be repeated dozens of times within a day!",
      description_2: "",
      image: "assets/images/2-products/2 - advergames for marketing/8.png",
    },
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopAdvergamesForMarketing descriptions={descriptions} />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileAdvergamesForMarketing descriptions={descriptions} />
      </Box>
    </>
  );
};

export default AdvergamesForMarketingPage;
