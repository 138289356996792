import React, { useRef } from "react";
import DesktopFooter from "../../../component/2-Footer/1-DesktopFooter";
import DesktopMediaCard2 from "../../../component/3-Products/2-MediaCard_2/1-DesktopMediaCard_2";
import DesktopMediaCard1 from "../../../component/3-Products/1-MediaCard_1/1-DesktopMediaCard_1";
import DesktopAppBar from "../../../component/1-AppBar/1-DesktopAppBar";
import { Drawer, Box, List, ListItem, ListItemText, Collapse, Typography } from "@mui/material";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ClippedDrawer({ descriptions }) {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const scrollRefs = {
    // Define scroll references for each section
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
    "#titleID_4": useRef(null),
  };
  const handleClick1 = () => {
    setOpenMenu1(!openMenu1);
    setSelectedItem("#titleID_1");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_1"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
    setSelectedItem("#titleID_2");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_2"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
    setSelectedItem("#titleID_3");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_3"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  const handleClick4 = () => {
    setOpenMenu4(!openMenu4);
    setSelectedItem("#titleID_4");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_4"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  //#region TOP VIDEO CONTENT
  const topData = {
    type: "VIDEO",
    url: "https://s3.ap-southeast-1.amazonaws.com/cdn.imigames.io/videos/IMI+GAMES.mp4",
    title: " ",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <DesktopMediaCard2 data={topData} />;
  //#endregion

  //#region DESCRIPTIONS CONTENT

  let section_1 = <DesktopMediaCard1 data={descriptions.section_1} />;

  let section_2 = <DesktopMediaCard1 data={descriptions.section_2} />;

  let section_3 = <DesktopMediaCard1 data={descriptions.section_3} />;

  let section_4 = <DesktopMediaCard1 data={descriptions.section_4} />;

  const drawerWidth = 200;

  return (
    <Box>
      <DesktopAppBar />
      <Box sx={{ display: "flex", p: 0 }}>
        {/*         <CssBaseline />
         */}

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ height: getElementHeight(90) + "px" }}></Box>

          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem
                button
                onClick={handleClick1}
                style={{
                  backgroundColor: selectedItem === "#titleID_1" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="Websites" />
                {openMenu1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={openMenu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="What are Hyper-casual games?
"
                    />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick2}
                style={{
                  backgroundColor: selectedItem === "#titleID_2" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText
                  primary="Why have them on your Web?
"
                />
                {openMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="Increased Engagement
"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="Increased Traffic
"
                    />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Higher Conversion Rates" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Enhanced User Experience" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick3}
                style={{
                  backgroundColor: selectedItem === "#titleID_3" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText
                  primary="Why us?
"
                />
                {openMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="IMI specializes in the production of..." />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick4}
                style={{
                  backgroundColor: selectedItem === "#titleID_4" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText
                  primary="Interested in getting to know more on what we have on offer?
"
                />
                {openMenu4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="Contact us.
"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Box>
        </Drawer>

        <Box>
          {topContent}

          {/* Websites-AREA-Open */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_1"
                ref={scrollRefs["#titleID_1"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Websites
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(25) + "px",
                  fontFamily: "Poppins",
                }}
              >
                What are Hyper-casual games?
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                }}
              >
                Hyper-casual games are a genre of mobile games that are characterized by their simple mechanics, short play sessions, and high replayability. They are designed to
                be easy to pick up and play, with minimal instructions or tutorial required.
              </Typography>
            </Box>
          </Box>
          {/* Websites-AREA-Close */}

          {/* Why have them on your Web?-AREA-Open*/}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              id="#titleID_2"
              ref={scrollRefs["#titleID_2"]}
              sx={{
                fontWeight: "700",
                fontSize: getElementWidth(40) + "px",
                fontFamily: "Poppins",
                color: "#CD3C37",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Why have them on your Web?
            </Typography>
          </Box>
          {/* Why have them on your Web?-AREA-Close*/}
          {section_1}
          {section_2}
          {section_3}
          {section_4}

          {/* Why us?-AREA-Open */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_3"
                ref={scrollRefs["#titleID_3"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Why us?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                IMI specializes in the production of hyper casual games for niche markets. The 40 strong teams of Developers, Marketers, Designers and Psychologists will address
                concerns and objectives of each client on a case-by-case basis. Production of branded gaming content and customization for client requirements come easy to us. The
                almost assembly line- like production process of our gaming factory ensures that you get a quality guaranteed product at a fraction of a price!
              </Typography>
            </Box>
          </Box>
          {/* Why us?-AREA-Close */}

          {/* Interested in getting to know more on what we have on offer?-AREA-Open*/}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_4"
                ref={scrollRefs["#titleID_4"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Interested in getting to know more on what we have on offer?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Contact us.
              </Typography>
            </Box>
          </Box>
          {/* Interested in getting to know more on what we have on offer?
-AREA-Close*/}

          <DesktopFooter />
        </Box>
      </Box>
    </Box>
  );
}
