import React, { useRef } from "react";
import DesktopFooter from "../../../component/2-Footer/1-DesktopFooter";
import DesktopMediaCard2 from "../../../component/3-Products/2-MediaCard_2/1-DesktopMediaCard_2";
import DesktopMediaCard1 from "../../../component/3-Products/1-MediaCard_1/1-DesktopMediaCard_1";
import DesktopAppBar from "../../../component/1-AppBar/1-DesktopAppBar";
import { Drawer, Box, List, ListItem, ListItemText, Collapse, Typography } from "@mui/material";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ClippedDrawer({ descriptions }) {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const scrollRefs = {
    // Define scroll references for each section
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
  };
  const handleClick1 = () => {
    setOpenMenu1(!openMenu1);
    setSelectedItem("#titleID_1");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_1"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
    setSelectedItem("#titleID_2");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_2"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
    setSelectedItem("#titleID_3");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_3"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  const topData = {
    type: "VIDEO",
    url: "https://s3.ap-southeast-1.amazonaws.com/cdn.imigames.io/videos/Nero_squads.mp4",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };
  const topContent = <DesktopMediaCard2 data={topData} />;

  let section_1 = <DesktopMediaCard1 data={descriptions.section_1} />;

  let section_2 = <DesktopMediaCard1 data={descriptions.section_2} />;

  let section_3 = <DesktopMediaCard1 data={descriptions.section_3} />;

  let section_4 = <DesktopMediaCard1 data={descriptions.section_4} />;

  let section_5 = <DesktopMediaCard1 data={descriptions.section_5} />;

  const drawerWidth = 200;

  return (
    <Box>
      <DesktopAppBar />
      <Box sx={{ display: "flex", p: 0 }}>
        {/*         <CssBaseline />
         */}

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ height: getElementHeight(90) + "px" }}></Box>

          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem
                button
                onClick={handleClick1}
                style={{
                  backgroundColor: selectedItem === "#titleID_1" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="What's in the gaming industry for Telco?" />
                {openMenu1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={openMenu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Advantages unique to Telcom" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Solving salient issues in the industry" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick2}
                style={{
                  backgroundColor: selectedItem === "#titleID_2" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="How can a gaming platform help Telco?" />
                {openMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Increasing the number of users and the ARPU" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Improved brand visibility" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Brand positioning among the tech savvy" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick3}
                style={{
                  backgroundColor: selectedItem === "#titleID_3" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="What can we do?" />
                {openMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="We are a gaming factory..." />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Box>
        </Drawer>

        <Box>
          {topContent}

          {/* What's in the Gaming Industry for Telco?-AREA-Open */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_1"
                ref={scrollRefs["#titleID_1"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What's in the Gaming Industry for Telco?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                The gaming industry is 80% larger than the movie industries around the world. With rapid growth it is estimated to reach USD 270 Billion by 2025.
              </Typography>
            </Box>
          </Box>
          {/* What's in the Gaming Industry for Telco?-AREA-Close */}

          {section_1}
          {section_2}

          {/*How can a gaming platform help Telco?-AREA-Open*/}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
              width: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
                width: "80%",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_2"
                ref={scrollRefs["#titleID_2"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                How can a gaming platform help Telco?
              </Typography>
            </Box>
          </Box>
          {/*How can gaming platform help Telco?-AREA-Close*/}

          {section_3}
          {section_4}
          {section_5}

          {/* What can we do?-AREA-Open */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_3"
                ref={scrollRefs["#titleID_3"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What can we do?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                We are a gaming factory. Our assembly line work process enables us to provide custom made games at a fraction of price. Whatever the gaming need may be, from
                hyper-casual gaming portals with an array of games, to multiplayer first person shooters we can provide tailor made gaming solutions with highly secured, real-time
                leaderboard modules.
              </Typography>
            </Box>
          </Box>
          {/* What can we do?-AREA-Close */}

          <DesktopFooter />
        </Box>
      </Box>
    </Box>
  );
}
