import { Box } from "@mui/material";
import React from "react";
import DesktopTelcoPartnership from "./2-DesktopTelcoPartnership";
import MobileTelcoPartnership from "./3-MobileTelcoPartnership";

const TelcoPartnerShipPage = () => {
  const descriptions = {
    section_1: {
      title: "Advantages unique to Telcom",
      subTitle: "",
      description_1:
        "Telcos are uniquely positioned to lead the pack in the gaming industry. Telcos have access to a huge customer base, and gaming presents a massive opportunity to monetize this reach. Telcos have an unparalleled upper hand when it comes to infrastructure.",
      description_2: "",
      image: "assets/images/2-products/1 - telco partnership/1.png",
    },

    section_2: {
      title: "Solving salient issues in the industry",
      subTitle: "",
      description_1:
        "Plus, it helps Telco find an answer to one of its most worrying problems, User attrition. It is said that nearly 60% of customers switch their mobile service provider every year. Yet, herein lies a golden opportunity as well. Research shows that around 75% of new customers coming to MSPs are from another provider.",
      description_2: "",
      image: "assets/images/2-products/1 - telco partnership/2.png",
    },

    section_3: {
      title: "Increasing the number of users and the ARPU",
      subTitle: "",
      description_1:
        "A gaming platform with a loyalty point system can be used to Reduce routine attrition: Due to loyalty programme and opportunities to win offers. Improve recruitment of new users: Due to Increased visibility and opportunities to win offers) A high proportion of the new users would be gamers and other heavy data users. Therefore, not just the sheer number but average revenue per user (ARPU) increases as well.",
      description_2: "",
      image: "assets/images/2-products/1 - telco partnership/3.png",
    },

    section_4: {
      title: "Improved brand visibility",
      subTitle: "",
      description_1:
        "Gaming ecosystem is a buzzing social sphere Players take it to social media to share their achievements. Feeling a sense of uniqueness is among the top 5 motivators to play games This can potentially create tremendous levels of organic  traction for the brand in social media circuits.",
      description_2: "",
      image: "assets/images/2-products/1 - telco partnership/4.png",
    },

    section_5: {
      title: "Brand positioning among the tech savvy",
      subTitle: "",
      description_1:
        "Being associated with the gaming industry and creating exciting value propositions such as low-latency connections, makes these telco companies be viewed as game-changers. Once again attracting high-end users, driving-up the ARPU (average revenue per user).",
      description_2: "",
      image: "assets/images/2-products/1 - telco partnership/5.png",
    },
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <DesktopTelcoPartnership descriptions={descriptions} />
      </Box>
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        <MobileTelcoPartnership descriptions={descriptions} />
      </Box>
    </>
  );
};

export default TelcoPartnerShipPage;
