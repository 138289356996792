import { Box, Card, CardContent, CardMedia, Fade, Grid, Typography } from "@mui/material";
import React from "react";
import DesktopAppBar from "../../component/1-AppBar/1-DesktopAppBar";
import DesktopFooter from "../../component/2-Footer/1-DesktopFooter";
import { getElementWidth, getElementHeight } from "../../utils/ScreenManager";
import { Link } from "react-router-dom";
import { ReactComponent as WaveSvg } from "./wave.svg";
import ClientsWebp from "./clients.webp";

const DesktopHome = ({ productsData, productIndex, setProductIndex }) => {
  const currentProductLink = productsData[productIndex].link;

  const handleClick = (index) => {
    setProductIndex(index);
  };

  //#region TOP CONTENT
  const topContent = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "transparent",
          height: getElementHeight(250) + "px",
          marginTop: getElementHeight(90) + "px",
        }}
      >
        <Link to={currentProductLink} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
          {productsData.map(({ id, text, subtext, image, link }, index) => {
            return productIndex === index ? (
              <Fade key={index} in={true} timeout={1500}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: getElementWidth(100) + "px",
                    }}
                  ></Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: getElementWidth(580) + "px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "700",
                        fontSize: getElementWidth(40) + "px",
                        fontFamily: "Poppins",
                        color: "#CD3C37",
                      }}
                    >
                      {text}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        fontSize: getElementWidth(15) + "px",
                        fontFamily: "Poppins",
                        color: "black",
                      }}
                    >
                      {subtext}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: getElementWidth(320) + "px",
                      width: getElementWidth(320) + "px",
                    }}
                  >
                    <img width={getElementWidth(250) + "px"} height={getElementWidth(250) + "px"} alt="IMG" src={image} />
                  </Box>
                </Box>
              </Fade>
            ) : null;
          })}
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            justifyContent="center"
            spacing={1}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {productsData.map(({ id, text, subtext, image, link }, index) => {
              return (
                <Grid key={id} item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: getElementWidth(40) + "px",
                      height: getElementWidth(40) + "px",
                      border: index === productIndex ? "1px solid #CD3C37" : "1px solid white",
                      borderRadius: "20%",
                    }}
                  >
                    <img
                      width={getElementWidth(40) + "px"}
                      height={getElementWidth(40) + "px"}
                      alt="IMG"
                      src={image}
                      onClick={() => handleClick(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <WaveSvg style={{ width: "100%", height: "auto" }} alt="wave-splatter" />
    </>
  );
  //#endregion

  //#region PARTNERS CONTENT

  const partnersContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        paddingBottom: getElementWidth(30) + "px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingBottom: getElementWidth(30) + "px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: getElementWidth(50) + "px",
          }}
        >
          Partners
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center", fontSize: getElementWidth(30) + "px" }}>
          A few of many who has trusted us.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: getElementWidth(1280) + "px",
          margin: "auto",
        }}
      >
        <img src={ClientsWebp} alt="clients" width="90%" />
      </Box>
    </Box>
  );
  //#endregion

  //#region PRODUCTS
  const productsContent = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          paddingTop: getElementWidth(30) + "px",
          paddingBottom: getElementWidth(30) + "px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: getElementWidth(30) + "px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: getElementWidth(50) + "px",
            }}
          >
            Products
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center", fontSize: getElementWidth(30) + "px" }}>
            We have specialized a few products for you
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={getElementWidth(50) + "px"}>
            {productsData.map(({ id, text, subtext, image, link }) => {
              return (
                <Grid item sx={{ textAlign: "center" }} key={id}>
                  <Link to={link} style={{ textDecoration: "none" }}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        height: "500px",
                        width: "360px",
                        borderRadius: "4px",
                        boxShadow: " rgb(0 0 0 / 16%) 0px 0px 9px -1px",
                      }}
                    >
                      <CardMedia sx={{ width: "350px", height: "350px" }} image={image} title="Telco Partnerships" />
                      <CardContent>
                        <Typography
                          component="div"
                          sx={{
                            fontWeight: "700",
                            textAlign: "center",
                            fontSize: "25px",
                          }}
                        >
                          {text}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{
                            fontWeight: "400",
                            textAlign: "center",
                            fontSize: "18px",
                          }}
                        >
                          {subtext}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <DesktopAppBar />
      {topContent}
      {partnersContent}
      {productsContent}
      <DesktopFooter />
    </Box>
  );
};

export default DesktopHome;
