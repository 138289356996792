import { Box, Typography } from "@mui/material";
import React from "react";
import { getElementWidth } from "../../utils/ScreenManager";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { PathsUrls } from "../../utils/Data";

export default function DesktopFooter() {
  return (
    <Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <div
        style={{
          display: "flex",

          textAlign: "right",
          justifyContent: "flex-end",
        }}
      >
        <img
          style={{
            marginBottom: "-10px",
            width: getElementWidth(320) + "px",
          }}
          src="assets/images/dancer_image.png"
          alt="IMI dancer"
        />
      </div>

      <Box
        sx={{
          display: "flex",
          height: getElementWidth(400) + "px",
          width: "100%",
          backgroundColor: "#2C2626",
          justifyContent: "space-between",
        }}
      >
        {/* first column */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: getElementWidth(50) + "px",
            }}
          ></Box>

          <Link to={PathsUrls.home} style={{ textDecoration: "none" }}>
            <img width={getElementWidth(150) + "px"} height={getElementWidth(215) + "px"} src="assets/images/appbar_logo.png" alt="" />
          </Link>
        </Box>

        {/* second column */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "35%",
            alignItems: "center",
            marginRight: getElementWidth(60) + "px",
          }}
        >
          <Box
            sx={{
              height: getElementWidth(50) + "px",
            }}
          ></Box>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: getElementWidth(18) + "px",
              fontFamily: "Poppins",
              color: "white",
            }}
          >
            Write to us
          </Typography>

          <Typography
            sx={{
              fontWeight: "200",
              fontSize: getElementWidth(15) + "px",
              fontFamily: "Poppins",
              color: "white",
            }}
          >
            hello@imigames.io
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              height: getElementWidth(50) + "px",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Link to="https://lk.linkedin.com/company/imi-games" target="_blank">
              <Typography
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth(15) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                <LinkedInIcon />
              </Typography>
            </Link>
            <Link to="https://m.facebook.com/imigamesofficial/" target="_blank">
              <Typography
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth(15) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                <FacebookIcon />
              </Typography>
            </Link>

            <Link to="https://instagram.com/imigamesofficial?igshid=YmMyMTA2M2Y=" target="_blank">
              <Typography
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth(15) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                <InstagramIcon />
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              height: getElementWidth(180) + "px",
            }}
          ></Box>
          <Typography
            sx={{
              fontWeight: "200",
              fontSize: getElementWidth(15) + "px",
              fontFamily: "Poppins",
              color: "white",
            }}
          >
            &#169; IMI Games 2023 All rights reserved
          </Typography>
        </Box>

        {/* third column*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "35%",
            height: getElementWidth(400) + "px",
          }}
        >
          <Box
            sx={{
              height: getElementWidth(30) + "px",
            }}
          ></Box>
          <Box
            sx={{
              height: getElementWidth(90) + "px",
            }}
          >
            <Box
              sx={{
                height: getElementWidth(20) + "px",
              }}
            ></Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: getElementWidth(18) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              IMI Games, Sri Lanka
            </Typography>
            <Typography
              sx={{
                fontWeight: "200",
                fontSize: getElementWidth(15) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              No 02, 6th Lane, Colombo 00300.
            </Typography>
          </Box>
          <Box
            sx={{
              height: getElementWidth(90) + "px",
            }}
          >
            <Box
              sx={{
                height: getElementWidth(20) + "px",
              }}
            ></Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: getElementWidth(18) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              IMI Games, Fiji
            </Typography>
            <Typography
              sx={{
                fontWeight: "200",
                fontSize: getElementWidth(15) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              12, Tamasua Street, Suva,Fiji Islands.
            </Typography>
          </Box>
          <Box
            sx={{
              height: getElementWidth(90) + "px",
            }}
          >
            <Box
              sx={{
                height: getElementWidth(20) + "px",
              }}
            ></Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: getElementWidth(18) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              IMI Games, UAE
            </Typography>
            <Typography
              sx={{
                fontWeight: "200",
                fontSize: getElementWidth(15) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              No 202-203, Pinnacle Sheikh Zayed Road,
            </Typography>
            <Typography
              sx={{
                fontWeight: "200",
                fontSize: getElementWidth(15) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              Al Barsha, Dubai, UAE.
            </Typography>
          </Box>

          <Box
            sx={{
              height: getElementWidth(10) + "px",
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
}
