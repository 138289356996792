import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { getElementWidth_Mobile, getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import MobileAppBar from "../../../component/1-AppBar/2-MobileAppBar";
import MobileImageCard from "../../../component/3-Products/1-MediaCard_1/2-MobileMediaCard_1";
import MobileMediaCard2 from "../../../component/3-Products/2-MediaCard_2/2-MobileMediaCard_2";
import MobileFooter from "../../../component/2-Footer/2-MobileFooter";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerAdverGames from "./DrawerAdverGames";

const MobileAdvergamesForMarketing = ({ descriptions }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState("white");

  const scrollRefs = {
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
    "#titleID_4": useRef(null),
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleItemClick = (id) => {
    if (scrollRefs[id].current) {
      const appBarHeight = getElementWidth_Mobile(160);
      const offset = scrollRefs[id].current.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const menuIconStyle = {
    position: "fixed",
    top: getElementHeight(30) + "px",
    right: getElementWidth(15) + "px",
    zIndex: 9999,
    cursor: "pointer",
    display: openDrawer ? "none" : "block",
    color: menuIconColor,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 100;
      const newMenuIconColor = scrollY > threshold ? "white" : "white";
      setMenuIconColor(newMenuIconColor);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //#region TOP VIDEO CONTENT
  const topData = {
    type: "VIDEO",
    url: "https://cdn.imigames.io/videos/v2/1X1_advernew.mp4",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <MobileMediaCard2 data={topData} />;

  //#endregion

  //#region DESCRIPTIONS CONTENT

  let section_1 = <MobileImageCard data={descriptions.section_1} />;

  let section_2 = <MobileImageCard data={descriptions.section_2} />;

  let section_3 = <MobileImageCard data={descriptions.section_3} />;

  let section_4 = <MobileImageCard data={descriptions.section_4} />;

  let section_5 = <MobileImageCard data={descriptions.section_5} />;

  let section_6 = <MobileImageCard data={descriptions.section_6} />;

  let section_7 = <MobileImageCard data={descriptions.section_7} />;

  let section_8 = <MobileImageCard data={descriptions.section_8} />;

  const descriptionsContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: getElementWidth_Mobile(20) + "px",
        paddingBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_1"
          ref={scrollRefs["#titleID_1"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What is an Advergame?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          An advergame is a type of video game that is used to advertise a product, service, or brand. Advergames are typically designed to be entertaining and engaging, while also
          providing an opportunity for the player to learn more about the product or brand. Everything in an advergame, from the storyline, to characters and even the color scheme
          of the gaming environment are designed to increase brand awareness and purchase intention.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_2"
          ref={scrollRefs["#titleID_2"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          Why go for an advergame?
        </Typography>
      </Box>
      {section_1}
      {section_2}
      {section_3}
      {section_4}
      {section_5}
      {section_6}
      <Box
        sx={{
          display: "flex",
          width: getElementWidth_Mobile(600) + "px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_3"
          ref={scrollRefs["#titleID_3"]}
          variant="h3"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          How can this be put to use?
        </Typography>
      </Box>
      {section_7}
      {section_8}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(600) + "px",
          textAlign: "center",
          paddingTop: getElementWidth_Mobile(20) + "px",
          paddingBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_4"
          ref={scrollRefs["#titleID_4"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What's holding you back?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          Please let us know, for we really can't think of any? :-D
        </Typography>
      </Box>
    </Box>
  );

  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MobileAppBar onMenuClick={handleDrawerToggle} />
      <MenuIcon style={menuIconStyle} onClick={handleDrawerToggle} />
      <DrawerAdverGames open={openDrawer} onClose={handleDrawerToggle} onItemClick={handleItemClick} />
      {topContent}
      {descriptionsContent}
      <MobileFooter />
    </Box>
  );
};

export default MobileAdvergamesForMarketing;
