import React, { useRef } from "react";
import DesktopFooter from "../../../component/2-Footer/1-DesktopFooter";
import DesktopMediaCard2 from "../../../component/3-Products/2-MediaCard_2/1-DesktopMediaCard_2";
import DesktopMediaCard1 from "../../../component/3-Products/1-MediaCard_1/1-DesktopMediaCard_1";
import DesktopAppBar from "../../../component/1-AppBar/1-DesktopAppBar";
import { Drawer, Box, List, ListItem, ListItemText, Collapse, Typography } from "@mui/material";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ClippedDrawer({ descriptions }) {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const scrollRefs = {
    // Define scroll references for each section
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
  };
  const handleClick1 = () => {
    setOpenMenu1(!openMenu1);
    setSelectedItem("#titleID_1");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_1"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
    setSelectedItem("#titleID_2");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_2"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
    setSelectedItem("#titleID_3");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_3"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  //#region TOP VIDEO CONTENT
  const topData = {
    type: "IMAGE",
    url: "assets/images/1-home/1 - products/digitaltherapeutics.gif",
    title: "",
    subTitle: "",
    description_1: "",
    description_2: "",
  };

  const topContent = <DesktopMediaCard2 data={topData} />;

  //#endregion

  //#region MID DESCRIPTIONS CONTENT

  let section_1 = <DesktopMediaCard1 data={descriptions.section_1} />;

  let section_2 = <DesktopMediaCard1 data={descriptions.section_2} />;

  const drawerWidth = 200;

  return (
    <Box>
      <DesktopAppBar />
      <Box sx={{ display: "flex", p: 0 }}>
        {/*         <CssBaseline />
         */}

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ height: getElementHeight(90) + "px" }}></Box>

          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem
                button
                onClick={handleClick1}
                style={{
                  backgroundColor: selectedItem === "#titleID_1" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="What are Digital Therapeutics?" />
                {openMenu1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={openMenu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Digital Therapeutics combines...." />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick2}
                style={{
                  backgroundColor: selectedItem === "#titleID_2" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="Use of Digital Games for Cognitive Rehabilitation:" />
                {openMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="One of the significant applications..." />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={handleClick3}
                style={{
                  backgroundColor: selectedItem === "#titleID_3" ? "#f5d7d6" : "inherit",
                }}
              >
                <ListItemText primary="Our involvement in Digital Therapeutics" />
                {openMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText primary="Temporal lobe epilepsy (TLE) with Hippocampal Sclerosis (HS)" />
                  </ListItem>
                  <ListItem button style={{ paddingLeft: 40 }}>
                    <ListItemText
                      primary="Cognitive deficits associated with Temporal lobe epilepsy (TLE) with Hippocampal Sclerosis (HS)
"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Box>
        </Drawer>

        <Box>
          {topContent}

          {/* What are Digital Therapeutics?-AREA-Open */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_1"
                ref={scrollRefs["#titleID_1"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                What are Digital Therapeutics?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Digital Therapeutics combines digital technology and medical science to provide evidence-based therapeutic interventions for patients suffering from various chronic
                conditions including diabetes, cardiovascular diseases, respiratory disorders, mental health disorders, and neurological conditions. These programs are clinically
                validated and can be used as standalone therapies or in conjunction with traditional treatments.
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(15) + "px",
                  fontFamily: "Poppins",
                  fontStyle: "italic",
                }}
              >
                Evidence: Hollis, C., Falconer, C. J., Martin, J. L., Whittington, C., Stockton, S., Glazebrook, C., ... & Davies, E. B. (2017). Annual research review: Digital
                health interventions for children and young people with mental health problems: a systematic and meta-review. Journal of Child Psychology and Psychiatry, 58(4),
                474-503. doi: 10.1111/jcpp.12663Gustafson, D. H., McTavish, F. M., Chih, M. Y., Atwood, A. K., Johnson, R. A., Boyle, M. G., ... & Shah, D. V. (2014). A smartphone
                application to support recovery from alcoholism: A randomized clinical trial. Journal of Substance Abuse Treatment, 46(1), 54-60. doi: 10.1016/j.jsat.2013.08.003
              </Typography>
            </Box>
          </Box>
          {/*What are Digital Therapeutics?-AREA-Close */}

          {/*Use of Digital Games for Cognitive Rehabilitation:-AREA-Open*/}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "80%",
                paddingTop: getElementWidth(20) + "px",
                paddingBottom: getElementWidth(20) + "px",
              }}
            >
              <Typography
                variant="h6"
                id="#titleID_2"
                ref={scrollRefs["#titleID_2"]}
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth(40) + "px",
                  fontFamily: "Poppins",
                  color: "#CD3C37",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Use of Digital Games for Cognitive Rehabilitation:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(20) + "px",
                  fontFamily: "Poppins",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                One of the significant applications of digital therapeutics is cognitive rehabilitation. Cognitive rehabilitation is a therapeutic approach that aims to improve the
                cognitive function of patients suffering from neurological conditions such as traumatic brain injury, stroke, epilepsy or dementia. Digital games have emerged as an
                effective tool for cognitive rehabilitation due to their ability to engage patients in a fun and interactive manner. Digital games for cognitive rehabilitation are
                designed to target specific cognitive domains such as attention, memory, and executive function. These games use evidence-based interventions such as errorless
                learning, spaced retrieval, and cognitive training to improve cognitive function in patients.
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: getElementWidth(15) + "px",
                  fontFamily: "Poppins",
                  fontStyle: "italic",
                }}
              >
                Evidence: A systematic review and meta-analysis published in the Journal of Neuro Engineering and Rehabilitation evaluated the effectiveness of digital games for
                cognitive rehabilitation. The study found that digital games were effective in improving cognitive function in patients with neurological conditions such as stroke
                and traumatic brain injury. Towers, A., Smyth, K., & McNeely, H. (2019). Use of commercial video games for cognitive rehabilitation: a systematic review. Journal of
                Neuro Engineering and Rehabilitation, 16(1), 1-16. doi: 10.1186/s12984-019-0534-1
              </Typography>
            </Box>
          </Box>
          {/*Use of Digital Games for Cognitive Rehabilitation:-AREA-Close*/}

          {/* Our involvement in Digital Therapeutics-AREA-Open */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: getElementWidth(20) + "px",
              paddingBottom: getElementWidth(20) + "px",
            }}
          >
            <Typography
              variant="h6"
              id="#titleID_3"
              ref={scrollRefs["#titleID_3"]}
              sx={{
                fontWeight: "700",
                fontSize: getElementWidth(40) + "px",
                fontFamily: "Poppins",
                color: "#CD3C37",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Our involvement in Digital Therapeutics
            </Typography>
          </Box>
          {/* Our involvement in Digital Therapeutics-AREA-Close */}
          {section_1}
          {section_2}

          <DesktopFooter />
        </Box>
      </Box>
    </Box>
  );
}
