import React,{useRef} from "react";
import {
  Drawer,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;

const DrawerTelco = ({ open, onClose, onItemClick }) => {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const scrollRefs = {
    // Define scroll references for each section
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
  };
  const handleClick1 = () => {
    setOpenMenu1(!openMenu1);
    onItemClick("#titleID_1");
    setSelectedItem("#titleID_1");
    const appBarHeight = 80; 
    window.scrollTo({
      top: scrollRefs["#titleID_1"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
    onItemClick("#titleID_2");
    setSelectedItem("#titleID_2");
    const appBarHeight = 80; 
    window.scrollTo({
      top: scrollRefs["#titleID_2"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
    onItemClick("#titleID_3");
    setSelectedItem("#titleID_3");
    const appBarHeight = 80;
    window.scrollTo({
      top: scrollRefs["#titleID_3"].current.offsetTop - appBarHeight,
      behavior: "smooth",
    });
  };
  return (
    <Drawer
      style={{ width: drawerWidth, flexShrink: 0 }}
      variant="permanent "
      anchor="right"
      open={open}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", pr: 1 }}>
        <IconButton onClick={onClose} color="inherit" edge="end">
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <ListItem
          button
          onClick={handleClick1}
          style={{
            backgroundColor: selectedItem === "#titleID_1" ? "#f5d7d6" : "inherit",
          }}
        >
          <ListItemText primary="What's in the gaming industry for Telco?" />
          {openMenu1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>

        <Collapse in={openMenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button style={{ paddingLeft: 40 }}>
              <ListItemText primary="Advantages unique to Telcom" />
            </ListItem>
            <ListItem button style={{ paddingLeft: 40 }}>
              <ListItemText primary="Solving salient issues in the industry" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={handleClick2}
          style={{
            backgroundColor: selectedItem === "#titleID_2" ? "#f5d7d6" : "inherit",
          }}
        >
          <ListItemText primary="How can a gaming platform help Telco?" />
          {openMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openMenu2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button style={{ paddingLeft: 40 }}>
              <ListItemText primary="Increasing the number of users and the ARPU" />
            </ListItem>
            <ListItem button style={{ paddingLeft: 40 }}>
              <ListItemText primary="Improved brand visibility" />
            </ListItem>
            <ListItem button style={{ paddingLeft: 40 }}>
              <ListItemText primary="Brand positioning among the tech savvy" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={handleClick3}
          style={{
            backgroundColor: selectedItem === "#titleID_3" ? "#f5d7d6" : "inherit",
          }}
        >
          <ListItemText primary="What can we do?" />
          {openMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openMenu3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button style={{ paddingLeft: 40 }}>
              <ListItemText primary="We are a gaming factory..." />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default DrawerTelco;
