import { Box } from "@mui/material";
import React from "react";
import DesktopDigitalTherapeutics from "./2-DesktopDigitalTherapeutics";
import MobileDigitalTherapeutics from "./3-MobileDigitalTherapeutics";

const DigitalTherapeuticsPage = () => {
  const descriptions = {
    section_1: {
      title: "Temporal lobe epilepsy (TLE) with Hippocampal Sclerosis (HS)",
      subTitle: "",
      description_1:
        "Our latest involvement in Digital Therapeutics include the production of a digitally delivered intervention for cognitive rehabilitation of Temporal Epilepsy Patients with Hippocampal Sclerosis. Temporal lobe epilepsy (TLE) is a type of epilepsy characterized by seizures originating from the temporal lobe of the brain. Hippocampal sclerosis (HS) is the most common cause of TLE in adults, and it is a pathological condition characterized by the loss of neurons and glial cells in the hippocampus.",
      description_2: "",
      image: "assets/images/2-products/3 - digital therapeutics/1.png",
    },

    section_2: {
      title: "Cognitive deficits associated with Temporal lobe epilepsy (TLE) with Hippocampal Sclerosis (HS)",
      subTitle: "",
      description_1:
        "HS can lead to cognitive impairment, including memory deficits, language difficulties, and executive dysfunction. Therefore, cognitive rehabilitation is an essential component of the management of patients with TLE and HS. With experts in the field of Neurology, Neuro-Psychiatry and Psychology, we’ve produced a digital intervention for gradual cognitive rehabilitation of TLE patients with cognitive deficits. The clinical trials for the said intervention are now being conducted following registration in the clinical trial registry.",
      description_2: "",
      image: "assets/images/2-products/3 - digital therapeutics/2.png",
    },
  };
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopDigitalTherapeutics descriptions={descriptions} />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileDigitalTherapeutics descriptions={descriptions} />
      </Box>
    </>
  );
};

export default DigitalTherapeuticsPage;
