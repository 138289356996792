import { Box } from "@mui/material";
import React from "react";
import DesktopGamingForWebsites from "./2-DesktopGamingForWebsites";
import MobileGamingForWebsites from "./3-MobileGamingForWebsites";

const GamingForWebsitesPage = () => {
  const descriptions = {
    section_1: {
      title: "Increased Engagement",
      subTitle: "27% higher than websites without games!",
      description_1:
        "Games can be a great way to keep visitors on a website longer, as they provide a form of entertainment and engagement. Utilizing games can help keep visitors on the site longer and increase the chances of them returning to the site.",
      description_2:
        "Evidence: A study by the University of Rochester found that websites with games had a 38% higher page view count and a 56% higher return visit rate compared to websites without games. Another study by the University of Tampere in Finland found that websites with games had a 27% higher user engagement rate compared to websites without games.",
      image: "assets/images/2-products/5 - gaming for websites/1.png",
    },

    section_2: {
      title: "Increased Traffic",
      subTitle: "30% Higher Web Traffic!",
      description_1:
        "Games can be a powerful tool for increasing website traffic by providing a form of entertainment for visitors. They can also be used to reach new audiences and expand a web-site's user base as they are often shared on social media, leading more people to discover the web-site. This way of user acquisition is extremely effective as it preferentially targets those in the close social circuits of regular users.",
      description_2: "Evidence: A case study by the online retailer, Zulily, found that their game increased website traffic by 30%.",
      image: "assets/images/2-products/5 - gaming for websites/2.png",
    },
    section_3: {
      title: "Higher Conversion Rates",
      subTitle: "Over X3 times the conversion rate",
      description_1:
        "Games can be used to engage visitors and turn them into customers by providing an interactive experience with a brand or offering a reward for completing a game or providing contact information. Evidence A study by the University of California, Berkeley found that website visitors who played an interactive game were more likely to make a purchase. The study found that the conversion rate for visitors who played the game was 9.3%, compared to 2.4% for visitors who did not play the game. A study by the University of Missouri found that website visitors who played a game were more likely to provide personal information, such as an email address. The study found that the conversion rate for visitors who played the game was 40%, compared to 12% for visitors who did not play the game.",
      description_2: "",
      image: "assets/images/2-products/5 - gaming for websites/3.png",
    },

    section_4: {
      title: "Enhanced User Experience",
      subTitle: "Over 15% increase in user satisfaction",
      description_1: "Games can be a powerful tool for improving the user experience on a website by providing a fun and interactive way for visitors to interact with a brand",
      description_2:
        "Evidence: A study by the University of Tampere in Finland found that users reported higher levels of satisfaction and enjoyment when interacting with websites that featured games. A case study by the e-commerce platform, BigCommerce, found that adding a game to a website led to a 15% increase in user satisfaction",
      image: "assets/images/2-products/5 - gaming for websites/4.png",
    },
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopGamingForWebsites descriptions={descriptions} />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileGamingForWebsites descriptions={descriptions} />{" "}
      </Box>
    </>
  );
};

export default GamingForWebsitesPage;
