import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DesktopHome from "./2-DesktopHome";
import MobileHome from "./3-MobileHome";
import { PathsUrls } from "../../utils/Data";

//#region DATA
const rootHomeAssetPath = "assets/images/1-home";

const productsData = [
  {
    id: "0",
    text: "Telco Partnerships",
    subtext: "Creating the Ultimate Gaming Platform",
    image: `${rootHomeAssetPath}/1 - products/telcopartnerships.gif`,
    link: PathsUrls.telcoPartnership,
  },
  {
    id: "1",
    text: "Advergames for Marketing",
    subtext: "Phenomenal Brand Promotion and Conversion",
    image: `${rootHomeAssetPath}/1 - products/advergames.gif`,
    link: PathsUrls.advergamesForMarketing,
  },
  {
    id: "2",
    text: "Digital Therapeutics",
    subtext: "Precedence Setting Healthcare Solutions",
    image: `${rootHomeAssetPath}/1 - products/digitaltherapeutics.gif`,
    link: PathsUrls.digitalTherapeutics,
  },
   {
     id: "3",
     text: "Corporate Wellness",
     subtext: "Cutting-edge Social Psychology Techniques in Practice",
    image: `${rootHomeAssetPath}/1 - products/corporatewellness.gif`,
    link: PathsUrls.corporateWellness,
  },
  {
    id: "4",
    text: "Gaming for Websites",
    subtext: "Next Level Web User-Experience",
    image: `${rootHomeAssetPath}/1 - products/gamesforwebsites.gif`,
    link: PathsUrls.gamingForWebsites,
  },
];

const partnersData = [
  {
    title: "Frimi",
    image: `${rootHomeAssetPath}/2 - Partners/frimi.svg`,
  },
  {
    title: "Bee Black",
    image: `${rootHomeAssetPath}/2 - Partners/bee_black-new.svg`,
  },
  {
    title: "Ooredoo",
    image: `${rootHomeAssetPath}/2 - Partners/ooredoo.svg`,
  },
  {
    title: "Ooredoo",
    image: `${rootHomeAssetPath}/2 - Partners/vodafone.svg`,
  },
  {
    title: "Dialog",
    image: `${rootHomeAssetPath}/2 - Partners/dialog.svg`,
  },
  {
    title: "Keells",
    image: `${rootHomeAssetPath}/2 - Partners/keells.svg`,
  },
  {
    title: "Nestle",
    image: `${rootHomeAssetPath}/2 - Partners/nestle.svg`,
  },
  {
    title: "World Health Organization",
    image: `${rootHomeAssetPath}/2 - Partners/who.svg`,
  },
  {
    title: "Dilmah",
    image: `${rootHomeAssetPath}/2 - Partners/dilmah.svg`,
  },
  {
    title: "SLT Mobitel",
    image: `${rootHomeAssetPath}/2 - Partners/slt.svg`,
  },
 
  
];
//#endregion

const HomePage = () => {
  const [productIndex, setProductIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProductIndex((prevIndex) => (prevIndex + 1) % productsData.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopHome productsData={productsData} productIndex={productIndex} partnersData={partnersData} setProductIndex={setProductIndex} />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileHome productsData={productsData} productIndex={productIndex} partnersData={partnersData}  />
      </Box>
    </>
  );
};

export default HomePage;
