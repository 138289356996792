export const screenSizeData = {
  referenceScreenHeight: 768,
  referenceScreenWidth: 1280,
  referenceMobileScreenHeight: 1520,
  referenceMobileScreenWidth: 720,
  currentScreenHeight: 0,
  currentScreenWidth: 0,
};

export function getElementHeight(y) {
  return (y * screenSizeData.currentScreenHeight) / screenSizeData.referenceScreenHeight;
}

export function getElementWidth(y) {
  return (y * screenSizeData.currentScreenWidth) / screenSizeData.referenceScreenWidth;
}

export function getElementHeight_Mobile(y) {
  return (y * screenSizeData.currentScreenHeight) / screenSizeData.referenceMobileScreenHeight;
}

export function getElementWidth_Mobile(y) {
  return (y * screenSizeData.currentScreenWidth) / screenSizeData.referenceMobileScreenWidth;
}
