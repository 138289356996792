import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { getElementWidth_Mobile, getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import MobileAppBar from "../../../component/1-AppBar/2-MobileAppBar";
import MobileImageCard from "../../../component/3-Products/1-MediaCard_1/2-MobileMediaCard_1";
import MobileMediaCard2 from "../../../component/3-Products/2-MediaCard_2/2-MobileMediaCard_2";
import MobileFooter from "../../../component/2-Footer/2-MobileFooter";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerWellness from "./DrawerWellness";

const MobileWellness = ({ descriptions }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState("white");

  const scrollRefs = {
    "#titleID_1": useRef(null),
    "#titleID_2": useRef(null),
    "#titleID_3": useRef(null),
    "#titleID_4": useRef(null),
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleItemClick = (id) => {
    if (scrollRefs[id].current) {
      const appBarHeight = getElementWidth_Mobile(160);
      const offset = scrollRefs[id].current.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const menuIconStyle = {
    position: "fixed",
    top: getElementHeight(30) + "px",
    right: getElementWidth(15) + "px",
    zIndex: 9999,
    cursor: "pointer",
    display: openDrawer ? "none" : "block",
    color: menuIconColor,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 100;
      const newMenuIconColor = scrollY > threshold ? "white" : "white";
      setMenuIconColor(newMenuIconColor);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //#region TOP  CONTENT
  const topData = {
    type: "VIDEO",
    url: "https://cdn.imigames.io/videos/v2/1X1_wellness.mp4",
  };

  const topContent = <MobileMediaCard2 data={topData} />;
  //#endregion

  //#region DESCRIPTIONS CONTENT

  let section_1 = <MobileImageCard data={descriptions.section_1} />;

  let section_2 = <MobileImageCard data={descriptions.section_2} />;

  let section_3 = <MobileImageCard data={descriptions.section_3} />;

  let section_4 = <MobileImageCard data={descriptions.section_4} />;

  let section_5 = <MobileImageCard data={descriptions.section_5} />;
  let section_6 = <MobileImageCard data={descriptions.section_6} />;
  let section_7 = <MobileImageCard data={descriptions.section_7} />;
  let section_8 = <MobileImageCard data={descriptions.section_8} />;
  let section_9 = <MobileImageCard data={descriptions.section_9} />;
  let section_10 = <MobileImageCard data={descriptions.section_10} />;

  const descriptionsContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: getElementWidth_Mobile(20) + "px",
        paddingBottom: getElementWidth_Mobile(20) + "px",
      }}
    >
      {/* What do we address area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: getElementWidth_Mobile(580) + "px",
          textAlign: "center",
        }}
      >
        <Typography
          id="#titleID_1"
          ref={scrollRefs["#titleID_1"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What do we address?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          We address two burning issues in the workforce
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="assets/images/2-products/4 - corporate wellness/illness.png"
            alt="image 1"
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
          />
          <img
            src="assets/images/2-products/4 - corporate wellness/burn-out.png"
            alt="image 2"
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
          />
        </div>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
          Both Illness and burnout incur heavy costs to the industry
        </Typography>
      </Box>

      {/* Direct Cost area and Indirect Cost areas */}
      {/*Section 1*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
            alt="IMG"
            src="assets/images/2-products/4 - corporate wellness/directCost.png"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: getElementWidth_Mobile(600) + "px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(35) + "px", fontFamily: "Poppins" }}>
            Direct Costs
          </Typography>
          <Box
            sx={{
              height: getElementWidth_Mobile(20) + "px",
            }}
          ></Box>

          <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
            Direct costs are due to
            <ul style={{ textAlign: "start" }}>
              <li>Wages paid to absent employees.</li>
              <li>High-cost replacement workers</li>
              <li>
                Poor quality of goods/services resulting from
                <ul style={{ listStyle: "disc" }}>
                  <li>Overtime </li>
                  <li>Fatigue </li>
                  <li>Understaffing </li>
                </ul>
              </li>
              <li>Reduced productivity</li>
              <li>
                Safety issues
                <ul style={{ listStyle: "disc" }}>
                  <li>inadequately trained employees filling in </li>
                  <li>rushing to catch up </li>
                </ul>
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
            These add-up to nearly <b>$ 1685 per worker</b> in a year.
          </Typography>
        </Box>
      </Box>
      {/*Section 2*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
            alt="IMG"
            src="assets/images/2-products/4 - corporate wellness/indirectCost.png"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: getElementWidth_Mobile(600) + "px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(35) + "px", fontFamily: "Poppins" }}>
            Indirect cost due to worker attrition and replacement
          </Typography>
          <Box
            sx={{
              height: getElementWidth_Mobile(20) + "px",
            }}
          ></Box>

          <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
            Such costs could be attributed to
            <ul style={{ textAlign: "start" }}>
              <li>
                Recruitment
                <ul style={{ listStyle: "disc" }}>
                  <li>Advertising </li>
                  <li>Interviewing </li>
                  <li>Screening </li>
                  <li>Hiring </li>
                </ul>
              </li>
              <li>
                Onboarding
                <ul style={{ listStyle: "disc" }}>
                  <li>Training </li>
                  <li>Management </li>
                </ul>
              </li>
              <li>Lower level of productivity of new workers </li>
            </ul>
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
            Every time a business replaces a salaried employee it costs nearly <b>6 months’ salary</b> of the recruited worker
          </Typography>
        </Box>
      </Box>

      {/* What do we Propose area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_2"
          ref={scrollRefs["#titleID_2"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What do we propose?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins", textAlign: "center" }}>
          <p>
            {" "}
            We propose gamifying healthy behaviour through an institution-wide tournament revolving around a <b>"wellness factor"</b> feeding directly into the scores of a leader
            board.{" "}
          </p>
          <p>The wellness factor depends on the performance on various health challenges an individual takes-up </p>
        </Typography>
      </Box>

      {/* What does this include area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(35) + "px", fontFamily: "Poppins" }}>
          What does this include?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins", textAlign: "center" }}>
          <p>
            {" "}
            We propose gamifying healthy behaviour through an institution-wide tournament revolving around a "wellness factor" feeding directly into the scores of a leader board.
          </p>
          <p>The wellness factor depends on the performance on various health challenges an individual takes-up </p>
        </Typography>
      </Box>

      {/* The health challenges include area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(35) + "px", fontFamily: "Poppins" }}>
          The health challenges include
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins", textAlign: "center", justifyContent: "center" }}>
          <p>
            A gamified step-counter. An Augmented Reality based Exergame. A Dietary Journal. Digitized Anxiolytic Mental Exercises. Information about individual risk factors,
            personal affinities and concerns are learnt through questions interspersed inbetween different game segments of the tournamemt.
          </p>
          <ol style={{ justifyContent: "center", textAlign: "start" }}>
            Information thus gathered will be used to
            <li>Recommend a personalized battery of screening tests, ensuring that no money or time is wasted on unnecessary investigations and the salient ones are done</li>
            <li>To individually tailor brief advices to align with personal affinities and concerns to maximise compliance </li>
            <li>To generate a company-level risk factor profile </li>
          </ol>
        </Typography>
      </Box>

      {/* What’s unique about our wellness solution? area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography
          id="#titleID_3"
          ref={scrollRefs["#titleID_3"]}
          variant="h6"
          sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
        >
          What’s unique about our wellness solution?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins", textAlign: "center", justifyContent: "center" }}>
          <p>
            Our product represents the perfect marriage between evidence based public health practices and social psychology to ensure high levels of engagement and maximum
            effectiveness.{" "}
          </p>
        </Typography>
      </Box>
      {section_1}

      {/* Large image area-1 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <img
          src="assets/images/2-products/4 - corporate wellness/draft_1.png"
          alt="draft_1"
          width={getElementWidth_Mobile(500) + "px"}
          height={getElementWidth_Mobile(500) + "px"}
        />

        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins", textAlign: "center", justifyContent: "center" }}>
          <p>
            Each disease to be prioritized, and each behavioral or environmental risk factor to be addressed through the intervention will be decided according to the country
            specific risk profile of the given to the age group and gender.
          </p>
          <p>This will ensure that the most prevalent and salient risk factors are addressed while keeping solely opinion-based decisions off the table.</p>
        </Typography>
        <img
          src="assets/images/2-products/4 - corporate wellness/draft_2.png"
          alt="draft_2"
          width={getElementWidth_Mobile(500) + "px"}
          height={getElementWidth_Mobile(500) + "px"}
        />
      </Box>

      {section_2}
      {section_3}
      {section_4}
      {section_5}
      {section_6}
      {section_7}
      {section_8}
      {section_9}
      {/* Individualized Approach area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(38) + "px", fontFamily: "Poppins", color: "blue" }}>
          Individualized Approach
        </Typography>{" "}
      </Box>
      {section_10}
      {/*Section 1 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            width={getElementWidth_Mobile(500) + "px"}
            height={getElementWidth_Mobile(500) + "px"}
            alt="IMG"
            src="assets/images/2-products/4 - corporate wellness/recommending.png"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: getElementWidth_Mobile(600) + "px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(35) + "px", fontFamily: "Poppins" }}>
            Recommending tailor-made screening packages
          </Typography>
          <Box
            sx={{
              height: getElementWidth_Mobile(20) + "px",
            }}
          ></Box>

          <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins", textAlign: "center" }}>
            Answers in mini-quizzes will be analyzed to identify the user’s specific health risk profile. An individualized screening battery would be suggested when it is deemed
            useful, based on this user specific risk profile. This will be superior to traditional one-size-fits-all biochemical screening test batteries as it
            <ul style={{ textAlign: "start" }}>
              <li>Prevents the waste of money in doing investigations with a very low yield </li>
              <li>Detects simple correctible conditions leading to low productivity/ attendance/ morale </li>
            </ul>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: getElementWidth_Mobile(20) + "px",
          marginBottom: getElementWidth_Mobile(20) + "px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: getElementWidth_Mobile(600) + "px",
            textAlign: "center",
          }}
        >
          <Typography
            id="#titleID_4"
            ref={scrollRefs["#titleID_4"]}
            variant="h6"
            sx={{ fontWeight: "700", fontSize: getElementWidth_Mobile(40) + "px", fontFamily: "Poppins", color: "#CD3C37" }}
          >
            In a nutshell, we aim to….
          </Typography>
          <Box
            sx={{
              height: getElementWidth_Mobile(20) + "px",
            }}
          ></Box>
          <Typography variant="h6" sx={{ fontWeight: "400", fontSize: getElementWidth_Mobile(25) + "px", fontFamily: "Poppins" }}>
            <ul style={{ textAlign: "start" }}>
              <li>
                Address worker burnout
                <ul style={{ listStyle: "disc" }}>
                  <li>By improving workplace social dynamics through an e-sports tournament within the institution. </li>
                </ul>
              </li>
              <li>
                Improve worker health by
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    Introducing a set of <b>health challenges</b> to create an inclusive culture to promote positive behavioral changes at workplace, leveraging social cognition{" "}
                  </li>
                  <li>
                    Suggesting <b>screening tests</b> (where necessary) based on user specific risk profile
                  </li>
                  <li>
                    Designing and delivering <b>“Brief Advices”</b> according to the transtheoretical theory in behavioral psychology to elicit a positive behavioral change
                  </li>
                  <li>
                    Assessing the <b>institutional level risk profile</b> and suggesting possible institutional level interventions*
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Box
            sx={{
              width: getElementWidth(150) + "px",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: getElementHeight(30) + "px",
        }}
      ></Box>
    </Box>
  );

  //#endregion

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MobileAppBar onMenuClick={handleDrawerToggle} />
      <MenuIcon style={menuIconStyle} onClick={handleDrawerToggle} />
      <DrawerWellness open={openDrawer} onClose={handleDrawerToggle} onItemClick={handleItemClick} />
      {topContent}
      {descriptionsContent}
      <MobileFooter />
    </Box>
  );
};

export default MobileWellness;
