import { Box, Typography } from "@mui/material";
import React from "react";
import { getElementWidth_Mobile } from "../../utils/ScreenManager";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { PathsUrls } from "../../utils/Data";

export default function MobileFooter() {
  return (
    <>
      <div>
        <img
          src="assets/images/dancer_image.png"
          alt="IMI dancer"
          style={{
            width: getElementWidth_Mobile(720) + "px",
            marginBottom: "-10px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //height: getElementWidth_Mobile(480) + "px",
            width: getElementWidth_Mobile(720) + "px",
            backgroundColor: "#2C2626",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Logo and Name section */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: getElementWidth_Mobile(700) + "px",
              paddingLeft: getElementWidth_Mobile(20) + "px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: getElementWidth_Mobile(30) + "px",
              }}
            ></Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                height: getElementWidth_Mobile(90) + "px",
              }}
            >
              <Link to={PathsUrls.home} style={{ textDecoration: "none" }}>
                <img
                  width={getElementWidth_Mobile(80) + "px"}
                  height={getElementWidth_Mobile(108) + "px"}
                  src="assets/images/appbar_logo.png"
                  alt=""
                />
              </Link>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  fontSize: getElementWidth_Mobile(40) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                IMI Games
              </Typography>
            </Box>

            {/* Info section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "justify",
                height: getElementWidth_Mobile(160) + "px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  fontSize: getElementWidth_Mobile(30) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                Write to us
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth_Mobile(20) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                hello@imigames.io
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "justify",
                height: getElementWidth_Mobile(160) + "px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  fontSize: getElementWidth_Mobile(30) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                IMI Games, Sri Lanka
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth_Mobile(20) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                No 02, 6th Lane, Colombo 00300.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "justify",
                height: getElementWidth_Mobile(160) + "px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  fontSize: getElementWidth_Mobile(30) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                IMI Games, Fiji
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth_Mobile(20) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
              >
                12, Tamasua Street, Suva, Fiji Islands.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "justify",
                height: getElementWidth_Mobile(190) + "px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  fontSize: getElementWidth_Mobile(30) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                  textAlign: "center",
                }}
              >
                IMI Games, UAE
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth_Mobile(20) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                  textAlign: "center",
                }}
              >
                First office,
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth_Mobile(20) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                  textAlign: "center",
                }}
              >
                No 202-203, Pinnacle Sheikh Zayed Road,
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "200",
                  fontSize: getElementWidth_Mobile(20) + "px",
                  fontFamily: "Poppins",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Al Barsha, Dubai, UAE.
              </Typography>
            </Box>

            {/*Social media section*/}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                height: getElementWidth_Mobile(160) + "px",
              }}
            >
              <Link
                to="https://lk.linkedin.com/company/imi-games"
                target="_blank"
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "200",
                    fontSize: getElementWidth_Mobile(15) + "px",
                    fontFamily: "Poppins",
                    color: "white",
                  }}
                >
                  <LinkedInIcon />
                </Typography>
              </Link>

              <Link
                to="https://m.facebook.com/imigamesofficial/"
                target="_blank"
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "200",
                    fontSize: getElementWidth_Mobile(15) + "px",
                    fontFamily: "Poppins",
                    color: "white",
                  }}
                >
                  <FacebookIcon />
                </Typography>
              </Link>

              <Link
                to="https://instagram.com/imigamesofficial?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "200",
                    fontSize: getElementWidth_Mobile(15) + "px",
                    fontFamily: "Poppins",
                    color: "white",
                  }}
                >
                  <InstagramIcon />
                </Typography>
              </Link>
            </Box>
          </Box>

          {/* Copyright-section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: getElementWidth_Mobile(20) + "px",
              padding: "10px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                fontSize: getElementWidth_Mobile(22) + "px",
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              &#169; IMI Games 2023 All rights reserved
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
}
