import { screenSizeData } from "./utils/ScreenManager";
import HomePage from "./pages/1-Home/1-index";
import { useWindowSize } from "./utils/hooks/useWindowSize";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { PathsUrls } from "./utils/Data";
import TelcoPartnerShipPage from "./pages/2- Products/1-Telco Partnership/1-index";
import AdvergamesForMarketingPage from "./pages/2- Products/2-Advergames For Marketing/1-index";
import DigitalTherapeuticsPage from "./pages/2- Products/3-Digital Therapeutics/1-index";
import GamingForWebsitesPage from "./pages/2- Products/5-GamingForWebsites/1-index";
import { useEffect } from "react";
import CoporateWellnessPage from "./pages/2- Products/4-CorporateWellness/1-index";

function App() {
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] = useWindowSize();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    console.log("1.1V");
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={PathsUrls.home} />} />
      <Route path={PathsUrls.home} element={<HomePage />} />
      <Route path={PathsUrls.telcoPartnership} element={<TelcoPartnerShipPage />} />
      <Route path={PathsUrls.advergamesForMarketing} element={<AdvergamesForMarketingPage />} />
      <Route path={PathsUrls.digitalTherapeutics} element={<DigitalTherapeuticsPage />} />
      <Route path={PathsUrls.gamingForWebsites} element={<GamingForWebsitesPage />} />
      <Route path={PathsUrls.corporateWellness} element={<CoporateWellnessPage />} />
    </Routes>
  );
}

export default App;
